import React, { Component } from "react";
import ToggleButton from "react-toggle-button";
import GeneratedApi from "../../../functions/generatedApi";

export class RowTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleAction(info, action) {
    let DATA_ID = info.dataId;
    let DATA_TYPE = info.dataType;

    const q = {
      dataId: DATA_ID,
      dataType: DATA_TYPE,
      action: action,
      message: "Action Completed",
    };
    GeneratedApi("", true, false)
      .post("reviewData", q)
      .then((response) => {
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  handleBoolChange = (bool, id, type) => {
    switch (type) {
      case "majorType":
        if (bool) {
          let params = { dataId: id, dataType: "event", action: "major_event_false", message: "dummy msg" };
          GeneratedApi("", true, false)
            .post("reviewData", params)
            .catch((error) => {
              console.log(error);
            });
        } else {
          let params = { dataId: id, dataType: "event", action: "major_event_true", message: "dummy msg" };
          GeneratedApi("", true, false)
            .post("reviewData", params)
            .catch((error) => {
              console.log(error);
            });
        }
        break;
      case "featureType":
        if (bool) {
          let params = { dataId: id, dataType: "event", action: "featured_event_false", message: "dummy msg" };
          GeneratedApi("", true, false)
            .post("reviewData", params)
            .catch((error) => {
              console.log(error);
            });
        } else {
          let params = { dataId: id, dataType: "event", action: "featured_event_true", message: "dummy msg" };
          GeneratedApi("", true, false)
            .post("reviewData", params)
            .catch((error) => {
              console.log(error);
            });
        }
        break;
      default:
        console.log("Something has gone horribly wrong");
    }
  };
  getMajorFeaturedState = (eventBool, dataId, type) => {
    let button;
    let button2;

    switch (type) {
      case "majorType":
        let self = this;
        eventBool
          ? (button = (
              <ToggleButton
                inactiveLabel={"✖"}
                activeLabel={"✔"}
                value={!this.state.value}
                onToggle={(value) => {
                  self.setState({
                    value: !this.state.value,
                  });
                  this.handleBoolChange(value, dataId, type);
                }}
              />
            ))
          : (button = (
              <ToggleButton
                inactiveLabel={"✖"}
                activeLabel={"✔"}
                value={this.state.value}
                onToggle={(value) => {
                  self.setState({
                    value: !value,
                  });
                  this.handleBoolChange(value, dataId, type);
                }}
              />
            ));
        break;

      case "featureType":
        let self2 = this;
        eventBool
          ? (button2 = (
              <ToggleButton
                inactiveLabel={"✖"}
                activeLabel={"✔"}
                value={!this.state.value1}
                onToggle={(value1) => {
                  self2.setState({
                    value1: !this.state.value1,
                  });
                  this.handleBoolChange(value1, dataId, type);
                }}
              />
            ))
          : (button2 = (
              <ToggleButton
                inactiveLabel={"✖"}
                activeLabel={"✔"}
                value={this.state.value1}
                onToggle={(value1) => {
                  self2.setState({
                    value1: !value1,
                  });
                  this.handleBoolChange(value1, dataId, type);
                }}
              />
            ));
        break;
      default:
        console.log("Something has gone horribly wrong");
    }
    let obj = {
      majorButton: button,
      featureButton: button2,
    };
    return obj;
  };
  fakeHandle = () => {};
  render() {
    const { showTitle, getTimeReadableByHumans, getSymbol, item, id, handleModal, handlePreview } = this.props;
    return (
      <tr key={`table-${id}`}>
        <th className="py-3 hover-blue pointer">
          <button
            className="remove-btn-style"
            onClick={() => {
              item.dataType === "company" ? handleModal(true, item) : item.dataType === "event" ? handlePreview(item) : this.fakeHandle();
            }}
          >
            {showTitle(item.dataType, item)}
          </button>
        </th>
        <td className="py-3">
          {getTimeReadableByHumans(item.lastEditTime).dayof} {getTimeReadableByHumans(item.lastEditTime).timeof}
        </td>

        <td className="py-3">{item.dataType}</td>
        <td className="py-3">{item.approvalStatus}</td>
        <td className="py-3">{item.authorEmail}</td>
        <td className="py-3">{item.dataType === "event" ? getSymbol(item.advertisementOptIn) : null}</td>
        <td className="py-3">{item.dataType === "event" ? getSymbol(item.featuredDataOptIn) : null}</td>
        <td className="py-3">
          {item.dataType === "event" ? this.getMajorFeaturedState(item.majorEvent, item.dataId, "majorType").majorButton : null}
        </td>
        <td className="py-3">
          {item.dataType === "event" ? this.getMajorFeaturedState(item.featured, item.dataId, "featureType").featureButton : null}
        </td>
        <td>
          <button
            className="btn btn-success"
            value="approve"
            onClick={(e) => {
              this.handleAction(item, e.target.value);
            }}
            type="button"
          >
            &#10004;
          </button>
          <button
            value="reject"
            className="btn btn-primary bg-custom"
            onClick={(e) => {
              this.handleAction(item, e.target.value);
            }}
            type="button"
          >
            &#10006;
          </button>
          <button
            value="delete"
            className="btn btn-danger"
            onClick={(e) => {
              this.handleAction(item, e.target.value);
            }}
            type="button"
          >
            &#128465;
          </button>
        </td>
      </tr>
    );
  }
}

export default RowTable;
