import React from 'react'
import { SelectLocation } from './SelectLocation';

export const Location = (props) => {
  const { handleUpdateState, formData } = props

  const handleLocation = (e) => {
	if(e.target.name === 'venueName') {
		formData.location[e.target.name] = e.target.value
	} else {
		formData.location.address[e.target.name] = e.target.value
	}
    props.handleUpdateState(formData)
  }

  return (
    <div className="w-100">
        <h4 className="mt-3 mb-4 text-center">Location</h4>
		<div className="row">
			<h5 className="col-12 col-md-6 my-2">Venue Name(if applicable)</h5>
			<div className="form-group col-12 col-md-6 mb-0">
				<input value={formData.location.venueName} onChange={handleLocation} name="venueName" type="text" className="form-control" placeholder="Venue Name"/>
			</div>
		</div>
		<div className="row mt-3">
			<h5 className="col-12 col-md-6 my-2">Street Address Line 1 {formData.eventType !== 'Webinar' && <sup className="text-danger">*</sup>} </h5>
			<div className="form-group col-12 col-md-6 mb-0">
				<input value={formData.location.address.street1} onChange={handleLocation} name="street1" id="street1" type="text" className="form-control" placeholder="Street 1"/>
			</div>
		</div>
		<div className="row mt-3">
			<h5 className="col-12 col-md-6 my-2">Street Address Line 2</h5>
			<div className="form-group col-12 col-md-6 mb-0">
				<input value={formData.location.address.street2} onChange={handleLocation} name="street2" type="text" className="form-control" placeholder="Street 2"/>
			</div>
		</div>
        
		<div className="row mt-3">
			<h5 className="col-12 col-md-6 my-2">City {formData.eventType !== 'Webinar' && <sup className="text-danger">*</sup>} </h5>
			<div className="form-group col-12 col-md-6 mb-0">
                <SelectLocation type="City" id="city" handleUpdateState={handleUpdateState} formData={formData} />
			</div>
		</div>
		<div className="row mt-3">
			<h5 className="col-12 col-md-6 my-2">State {formData.eventType !== 'Webinar' && <sup className="text-danger">*</sup>} </h5>
			<div className="form-group col-12 col-md-6 mb-0">
                <SelectLocation type="State" id="state" handleUpdateState={handleUpdateState} formData={formData} />
			</div>
		</div>
		<div className="row mt-3">
			<h5 className="col-12 col-md-6 my-2">State Code</h5>
			<div className="form-group col-12 col-md-6 mb-0">
				<input value={formData.location.address.stateCode} onChange={handleLocation} name="stateCode" type="text" className="form-control" placeholder="State Code"/>
			</div>
		</div>
		<div className="row mt-3">
			<h5 className="col-12 col-md-6 my-2">Country {formData.eventType !== 'Webinar' && <sup className="text-danger">*</sup>} </h5>
			<div className="form-group col-12 col-md-6 mb-0">
                <SelectLocation type="Country" id="country" handleUpdateState={handleUpdateState} handleLocation={handleLocation} formData={formData} />
			</div>
		</div>
		<div className="row mt-3">
			<h5 className="col-12 col-md-6 my-2">Country Code</h5>
			<div className="form-group col-12 col-md-6 mb-0">
				<input value={formData.location.address.countryCode} onChange={handleLocation} name="countryCode" type="text" className="form-control" placeholder="Country Code"/>
			</div>
		</div>
		<div className="row mt-3">
			<h5 className="col-12 col-md-6 my-2">Zip Code {formData.eventType !== 'Webinar' && <sup className="text-danger">*</sup>} </h5>
			<div className="form-group col-12 col-md-6 mb-0">
				<input value={formData.location.address.zipCode} onChange={handleLocation} id="zipCode" name="zipCode" required type="text" className="form-control" placeholder="Zip Code"/>
			</div>
		</div>
    </div>
  )
}
