const checkSegment = (target, formData) => {
  if(target.name === 'IoT') {
	if (target.checked){
		formData.eventSegment = [...formData.eventSegment, 'IoT']
	} else {
		formData.eventSegment = [...formData.eventSegment.filter( (item) => {
			return item !== 'IoT'
		})]
	}
  } else if(target.name === 'AI') {
	if (target.checked){
		formData.eventSegment = [...formData.eventSegment, 'AI']
	} else {
		formData.eventSegment = [...formData.eventSegment.filter( (item) => {
			return item !== 'AI'
		})]
	}
  } else if(target.name === 'Blockchain') {
	if (target.checked){
		formData.eventSegment = [...formData.eventSegment, 'Blockchain']
	} else {
		formData.eventSegment = [...formData.eventSegment.filter( (item) => {
			return item !== 'Blockchain'
		})]
	}
  } else if(target.name === 'AR/VR') {
	if (target.checked){
		formData.eventSegment = [...formData.eventSegment, 'AR/VR']
	} else {
		formData.eventSegment = [...formData.eventSegment.filter( (item) => {
			return item !== 'AR/VR'
		})]
	}
  } else if(target.name === 'Other Segment') {
	formData.eventSegment = [...formData.eventSegment.filter( (item) => {
		return item === 'AR/VR' || item === 'Blockchain' || item === 'AI' || item === 'IoT'
	})]
	if (target.value !== '') {
		formData.eventSegment = [...formData.eventSegment, target.value]
	}
  }

  return (
	formData
  )

}

export default checkSegment
