import React from 'react'
import style from './Spinner.module.scss'

function ManualSpinner({ height, position, addedClass }) {
    return (
        <div className={`${style.root} d-flex`}>
            <div className="spinner-border mx-auto" role="status">
              <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
}

export default ManualSpinner