import React, { Component } from "react";
import { NotificationManager } from "react-notifications";
import GeneratedApi from "../../../functions/generatedApi";
import RequiredFields from "../../../functions/RequiredFields";
import style from "../AddEvent.module.scss";
// import scrollToObject from '../../../functions/scrollToObject'

export default class AddOrganizers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organizers: [],
      stateOrganizers: [],
    };
    if (props.redirectInfo) {
      const filter = {
        search: { q: "", start: 0, limit: 30 },
        filters: [
          { attribute: "dataType", operator: "Contains", value: "company" },
          { attribute: "approvalStatus", operator: "Contains", value: "Pending Approval" },
        ],
        sort: { attribute: "rank", how: "asc" },
      };
      GeneratedApi("", true, false)
        .post("search_dynamo_ugc_records", filter)
        .then((response) => {
          const filterOrganizers = props.redirectInfo.organizers.map((item) => item.dataId);
          const org = response.data.filter((item) => filterOrganizers.includes(item.dataId));

          this.setState({ stateOrganizers: org });
        })
        .catch((error) => {
          console.log(error.response);
        });
    }
  }

  handleAddOrganizer = () => {
    let newOrganizer = {
      dataId: "-1",
      dataType: "company",
      company_name: "",
      company_nickname: "",
      company_url: "https://",
      company_address: { street_1: null, street_2: null, city: null, state: null, zipcode: null, country: null },
      company_sponsor_type: "",
    };
    this.setState({ organizers: [...this.state.organizers, newOrganizer] });
  };

  handleInput = (id, e, name) => {
    const data = this.state.organizers;
    const value = e.target.value;
    if (name === "company_url") {
      if (
        (value.length >= 4 && value.includes("www.")) ||
        (value.length === 3 && value.includes("www")) ||
        (value.length === 2 && value.includes("ww")) ||
        (value.length === 1 && value.includes("w")) ||
        value === ""
      ) {
        data[id][name] = `${data[id][name].split("://")[0]}://${value.replace(/https:\/\//gi, "").replace(/http:\/\//gi, "")}`;
      }
    } else {
      data[id][name] = value;
    }
    this.setState({ organizers: data });
  };

  handleDeleteOrganizer = (id) => {
    const data = this.state.organizers;
    this.setState({ organizers: data.filter((item, key) => key !== id) });
  };

  makeSuccess = (id) => {
    document.getElementById(`submitOrganizer-${id}`).classList.add("bg-success", "text-white");
    document.getElementById(`submitOrganizer-${id}`).classList.remove("bg-danger");
    document.getElementById(`organizer-block${id}`).classList.add("disable-block");
  };

  submitOrganizer = (id) => {
    let compName = document.getElementById(`company_name${id}`);
    let compNickname = document.getElementById(`company_nickname${id}`); //Optional
    let compSponsorType = document.getElementById(`company_sponsor_type${id}`);
    let compUrl = this.state.organizers[id].company_url.includes("www") ? this.state.organizers[id].company_url : "";

    RequiredFields(compName);
    RequiredFields(compNickname);
    RequiredFields(compSponsorType);
    RequiredFields(document.getElementById(`company_url${id}`), compUrl);

    if (!RequiredFields(compName) || !RequiredFields(compNickname) || !RequiredFields(compSponsorType) || !RequiredFields(document.getElementById(`company_url${id}`), compUrl)) {
      return null;
    }
    const { handleUpdateState, formData } = this.props;
    GeneratedApi("", true, false)
      .post("updateCompany", { data: this.state.organizers[id] })
      .then((response) => {
        response = response.data;
        formData.organizers = [...formData.organizers, response];
        handleUpdateState(formData);
        this.makeSuccess(id);
        NotificationManager.success("Organizer successfully submitted !", "Succeess message");
      })
      .catch((error) => {
        console.log(error.response);
        NotificationManager.error("Failed to submit Organizer", "Error message");
      });
  };

  handleSelectOption = (id, e) => {
    const { organizers } = this.state;
    organizers[id].company_url = `${e.target.value}${organizers[id].company_url.split("://")[1] ? organizers[id].company_url.split("://")[1] : ""}`;
    this.setState({ organizers });
  };

  render() {
    return (
      <div>
        <h4 id="organizers" className="my-3 text-center">
          Organizers(s)<sup className="text-danger">*</sup>
        </h4>

        <hr className="mb-4" />

        <div className="row">
          {/* from edited state */}
          {this.state.stateOrganizers.map((item, key) => (
            <div key={key} id={`organizer-blocks${key}`} className="col-12 col-md-6 disable-block">
              <h3 className="mb-4">New Organizer</h3>
              <h5 className="my-3 mb-0">Company's Offical Name</h5>
              <input
                value={item.company_name}
                onChange={(e) => this.handleInput(key, e, "company_name")}
                id={`company_name${key}`}
                type="text"
                className="form-control"
                placeholder="Company name"
              />

              <h5 className="my-3 mb-0">Nickname (Optional)</h5>
              <input
                value={item.company_nickname}
                onChange={(e) => this.handleInput(key, e, "company_nickname")}
                id={`company_nickname${key}`}
                type="text"
                className="form-control"
                placeholder="Nickname"
              />

              <h5 className="my-3 mb-0">Company's Website(URL)</h5>
              <div className="row">
                <div className="col-4 col-xl-3 pr-0">
                  <select
                    value={`${item.company_url.split("://")[0]}://`}
                    onChange={(e) => this.handleSelectOption(key, e)}
                    id={`company_url${key}`}
                    name="organizer website"
                    className="form-control"
                  >
                    <option value="http://">http</option>
                    <option value="https://">https</option>
                  </select>
                </div>
                <div className="col pl-0">
                  <input
                    value={item.company_url.split("://")[1] ? item.company_url.split("://")[1] : ""}
                    onChange={(e) => this.handleInput(key, e, "company_url")}
                    id={`company_url${key}`}
                    name="org url"
                    pattern="www.*"
                    className="form-control"
                    placeholder="www.google.com/"
                  />
                </div>
              </div>

              <h5 className="my-3 mb-0">Sponsor</h5>
              <input
                value={item.company_sponsor_type}
                onChange={(e) => this.handleInput(key, e, "company_sponsor_type")}
                id={`company_sponsor_type${key}`}
                type="text"
                className="form-control"
                placeholder="Sponsor"
              />

              <div className="row mx-0">
                <button
                  onClick={() => this.submitOrganizer(key)}
                  id={`submitOrganizer-${key}`}
                  className="btn my-3 orange-bg text-white mx-auto bg-success text-white"
                >
                  Submit Organizer
                </button>
                <button onClick={() => this.handleDeleteOrganizer(key)} className="btn my-3 btn-danger text-white mx-auto">
                  Delete Organizer
                </button>
              </div>
            </div>
          ))}
          {/* new added state */}
          {this.state.organizers.map((item, key) => (
            <div className="col-12 col-lg-6">
              <div className="border rounded mb-4">
                <div className={`p-2 w-100 h4 ${style["blue-background"]}`}>Organizer {key + 1}</div>
                <div key={key} id={`organizer-block${key}`} className="col-12">
                  
                  <h5 className="my-3 mb-0">Company's Offical Name</h5>
                  <input
                    value={item.company_name}
                    onChange={(e) => this.handleInput(key, e, "company_name")}
                    id={`company_name${key}`}
                    type="text"
                    className="form-control"
                    placeholder="Company name"
                  />

                  <h5 className="my-3 mb-0">Nickname (Optional)</h5>
                  <input
                    value={item.company_nickname}
                    onChange={(e) => this.handleInput(key, e, "company_nickname")}
                    id={`company_nickname${key}`}
                    type="text"
                    className="form-control"
                    placeholder="Nickname"
                  />

                  <h5 className="my-3 mb-0">Company's Website(URL)</h5>
                  <div className="row">
                    <div className="col-4 col-xl-3 pr-0">
                      <select
                        value={`${item.company_url.split("://")[0]}://`}
                        onChange={(e) => this.handleSelectOption(key, e)}
                        name="organizer website"
                        className="form-control"
                      >
                        <option value="http://">http</option>
                        <option value="https://">https</option>
                      </select>
                    </div>
                    <div className="col pl-0">
                      <input
                        value={item.company_url.split("://")[1] ? item.company_url.split("://")[1] : ""}
                        onChange={(e) => this.handleInput(key, e, "company_url")}
                        id={`company_url${key}`}
                        name="org url"
                        pattern="www.*"
                        className="form-control"
                        placeholder="www.google.com/"
                      />
                    </div>
                  </div>

                  <h5 className="my-3 mb-0">Sponsor</h5>
                  <input
                    value={item.company_sponsor_type}
                    onChange={(e) => this.handleInput(key, e, "company_sponsor_type")}
                    id={`company_sponsor_type${key}`}
                    type="text"
                    className="form-control"
                    placeholder="Sponsor"
                  />

                  <div className="row mx-0 mt-2 mb-2">
                    <button onClick={() => this.submitOrganizer(key)} id={`submitOrganizer-${key}`} className="btn my-2 orange-bg text-white mx-auto">
                      Submit Organizer
                    </button>
                    <button onClick={() => this.handleDeleteOrganizer(key)} className="btn my-2 btn-danger text-white mx-auto">
                      Delete Organizer
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {
          this.state.organizers.length > 0 && <hr className="my-4" />
        }
        <div className="mt-3 d-flex">
          <button onClick={() => this.handleAddOrganizer()} className="btn px-5 orange-bg text-white mx-auto">
            Add Organizer
          </button>
        </div>
        
      </div>
    );
  }
}
