import React from "react";
import eventTypes from "../../../jsonFiles/EventTypes.json";
import eventFormat from "../../../jsonFiles/EventFormat.json";
import CustomCheckBox from "../../../components/CustomCheckBox/CustomCheckBox";
import AddSpeaker from "./AddSpeaker";

const TypeAndFormat = (props) => {
  const { formData, handleUpdateState } = props;

  const handleSelectOption = (e) => {
    if (e.target.value === "Webinar" || formData.eventType === "Webinar") {
      formData.onlineOffline = "Online";
    }
    if ((e.target.id === "onlineOffline" && formData.eventType !== "Webinar") || e.target.id !== "onlineOffline") {
      formData[e.target.id] = e.target.value;
    }
    handleUpdateState(formData);
  };

  return (
    <div>
      <div className="row">
        <div className="col-12 col-md-6 mt-3">
          <h5>
            Type<sup className="text-danger">*</sup>
          </h5>
          <select value={formData.eventType} onChange={handleSelectOption} className="form-control" name="type" id="eventType">
            {eventTypes.map((item, key) => (
              <option key={`type-${key}`} value={item}>
                {item}
              </option>
            ))}
          </select>
          {formData.type === "Webinar" && (
            <label className="d-flex my-2" htmlFor="type">
              <CustomCheckBox
                name="type"
                id="type"
                onChange=""
                // checked={true}
              />
              <span className="ml-2"> Will this be webinar on demand?</span>
            </label>
          )}
        </div>
        <div className="col-12 col-md-6 mt-3">
          <h5>
            Format<sup className="text-danger">*</sup>
          </h5>
          <select value={formData.onlineOffline} onChange={handleSelectOption} className="form-control" name="format" id="onlineOffline">
            {eventFormat.map((item, key) => (
              <option key={`format-${key}`} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
      </div>
      {formData.eventType === "Webinar" && <AddSpeaker handleUpdateState={handleUpdateState} formData={formData} />}
    </div>
  );
};

export default TypeAndFormat;
