import React, { Component } from "react";
import AdminPanel from "./AdminPanel";
import GeneratedApi from "../../functions/generatedApi";

export class AdminPanelController extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterArray: [],
      responseArray: [],
      firstLoad: false,
      sort: {
        attribute: "rank",
        how: "asc",
      },
      search: {
        q: "",
        start: 0,
        limit: 30,
      },
    };
  }

  postSearch = () => {
    const q = {
      search: this.state.search,
      filters: this.state.filterArray.filter((item) => {
        return item.value !== "";
      }),
      sort: this.state.sort,
    };
    GeneratedApi("", true, false)
      .post("search_dynamo_ugc_records", q)
      .then((response) => {
        response = response.data;
        this.setState({
          responseArray: response.sort((a, b) => b.lastEditTime - a.lastEditTime),
          firstLoad: false,
        });
      })
      .catch((error) => {
        console.log(error.response);
        this.setState({
          firstLoad: false,
        });
      });
  };

  componentDidMount() {
    this.postSearch();
  }

  handleAddFilter = () => {
    this.setState({ filterArray: [...this.state.filterArray, { attribute: "", value: "" }] });
  };
  selectFilterChange = async (e) => {
    e.preventDefault();
    const { name, value, id } = e.target;
    let items = [...this.state.filterArray];
    let item = { ...items[id] };
    if (name === "attribute") {
      item = { value: item.value, operator: "Contains", attribute: value };
    } else {
      item = { attribute: item.attribute, operator: "Contains", value: value };
    }
    items[id] = item;
    await this.setState({ filterArray: items });
    await this.postSearch();
  };

  render() {
    const { filterArray, responseArray } = this.state;
    return (
      <AdminPanel
        advancedFilter={{
          filterArray,
          handleAddFilter: this.handleAddFilter,
          selectFilterChange: this.selectFilterChange,
        }}
        tableProps={{
          responseArray,
          getMajorFeaturedState: this.getMajorFeaturedState,
          ...this.props,
        }}
      />
    );
  }
}

export default AdminPanelController;
