import React from 'react'

const SelectionRow = (props) => {
  const { filterArray, selectFilterChange } = props;
	
  if(filterArray.length === 0) {
	return (
		<div />
	)
  }
  return (
	filterArray.map((item, key) => (
		<div key={key} className="row mx-0">
			<div className="my-2 mr-2">
				<select value={item.attribute} onChange={selectFilterChange} className="form-control" name="attribute" id={key}>
					<option value=""></option>
					<option value="dataType">Content Type</option>
					<option value="approvalStatus">Status</option>
				</select>
			</div>
			{
				item.attribute === 'dataType' && (
					<div className="my-2 mr-2">
						<select value={item.value} onChange={selectFilterChange} className="form-control" name="value" id={key}>
							<option value=""></option>
							<option value="event">Events</option>
							<option value="usecase">Use Cases</option>
							<option value="company">Companies</option>
							<option value="contact">Contacts</option>
						</select>
					</div>
				)
			}
			
			{
				item.attribute === 'approvalStatus' && (
					<div className="my-2 mr-2">
						<select value={item.value} onChange={selectFilterChange} className="form-control" name="value" id={key}>
							<option value=""></option>
							<option value="Approved">Approved</option>
							<option value="Pending Approval">Pending Approval</option>
							<option value="Deleted">Deleted</option>
							<option value="Rejected">Rejected</option>
						</select>
					</div>
				)
			}
			
		</div>
	))
  )
}

export default SelectionRow
