import React, { Component } from "react";
import UserPanel from "./UserPanel";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { Redirect } from "react-router-dom";
import GeneratedApi from "../../functions/generatedApi";

export default class UserPanelController extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterArray: [],
      responseArray: [],
      sort: {
        attribute: "rank",
        how: "asc",
      },
      search: {
        q: "",
        start: 0,
        limit: 30,
      },
      lastSorting: "asc",
      spinner: false,
    };
  }

  postSearch = () => {
    this.setState({ spinner: true });
    const q = {
      search: this.state.search,
      filters: this.state.filterArray.filter((item) => {
        return item.value !== "";
      }),
      sort: this.state.sort,
    };
    GeneratedApi("", true, false)
      .post("search_dynamo_ugc_records", q)
      .then((response) => {
        response = response.data;
        this.setState({
          responseArray: response.sort((a, b) => b.lastEditTime - a.lastEditTime),
          firstLoad: false,
          spinner: false,
        });
      })
      .catch((error) => {
        console.log(error.response);
        this.setState({
          firstLoad: false,
          spinner: false,
        });
      });
  };

  componentDidMount() {
    this.postSearch();
    this.Notify();
  }
  handleAddFilter = () => {
    this.setState({ filterArray: [...this.state.filterArray, { attribute: "", value: "" }] });
  };
  selectFilterChange = async (e) => {
    e.preventDefault();
    const { name, value, id } = e.target;
    let items = [...this.state.filterArray];
    let item = { ...items[id] };
    if (name === "attribute") {
      item = { value: item.value, operator: "Contains", attribute: value };
    } else {
      item = { attribute: item.attribute, operator: "Contains", value: value };
    }
    items[id] = item;
    await this.setState({ filterArray: items });
    await this.postSearch();
  };
  handleSearchChange = async (e) => {
    e.preventDefault();
    const { search } = this.state;
    const { target } = e;
    search.q = target.value;
    await this.setState({ search });
    if (target.value === "") {
      await this.postSearch();
    }
  };
  submitSearch = (e) => {
    e.preventDefault();
    this.postSearch();
  };
  handleDelete = (info) => {
    this.setState({ spinner: true });
    let DATA_ID = info.dataId;
    let DATA_TYPE = info.dataType;

    const q = {
      dataId: DATA_ID,
      dataType: DATA_TYPE,
      action: "delete",
      message: "This is a message alone with action",
    };
    GeneratedApi("", true, false)
      .post("reviewData", q)
      .then((response) => {
        NotificationManager.success(`${DATA_TYPE} successfully deleted`, "Success message");
        this.postSearch();
      })
      .catch((error) => {
        this.setState({ spinner: false });
        NotificationManager.error(`${DATA_TYPE} unsuccessfully deleted`, "Error message");
        console.log(error.response);
      });
  };
  handleUpdateTable = (data) => {
    return (
      <Redirect
        to={{
          pathname: "AddEvent",
          state: {
            formData: data,
          },
        }}
      />
    );
  };
  handleSort = () => {
    const { lastSorting, responseArray } = this.state;

    if (lastSorting === "asc") {
      this.setState({
        lastSorting: "desc",
        responseArray: responseArray.sort((a, b) => a.lastEditTime - b.lastEditTime),
      });
    } else {
      this.setState({
        lastSorting: "asc",
        responseArray: responseArray.sort((a, b) => b.lastEditTime - a.lastEditTime),
      });
    }
  };
  Notify = () => {
    const info = window.history.state;
    if (info && info.state && info.state.notification && info.state.notification.notify) {
      const { notification } = info.state;
      window.history.pushState({ type: notification.type, message: notification.message }, "state");
      NotificationManager.success(notification.message, notification.type);
    }
  };
  render() {
    const { filterArray, responseArray, search, spinner } = this.state;

    return (
      <div>
        <UserPanel
          searchVal={search.q}
          handleSearchChange={this.handleSearchChange}
          submitSearch={this.submitSearch}
          spinner={spinner}
          advancedFilter={{
            filterArray,
            handleAddFilter: this.handleAddFilter,
            selectFilterChange: this.selectFilterChange,
          }}
          tableProps={{
            handleDelete: this.handleDelete,
            responseArray,
            handleUpdateTable: this.handleUpdateTable,
            handleSort: this.handleSort,
            spinner,
          }}
        />
        <NotificationContainer />
      </div>
    );
  }
}
