import React, { useState, useEffect } from 'react'

const BannersAndRegistration = (props) => {
  const { formData, redirectInfo } = props
  const [ option, setOption ] = useState('https://')
  const [ url, setUrl ] = useState('')
  
   const handleLoadImage = (e, element) => {
	e.preventDefault();
	const { target } = e
    window.cloudinary.openUploadWidget({ height: 200, width: 150, crop: "imagga_scale", cloud_name: 'allpurposeflour', upload_preset: 't5rsav4b' },
      function (error, result) {

		if (error) {
			formData.images[target.name] = ''
			document.getElementById(element).innerHTML = "Unsuccess, Reclick to Resubmit"
			document.getElementById(element).classList.add("bg-danger", "text-white");
			document.getElementById(element).classList.remove("bg-success");
			document.getElementById(target.name).classList.add("d-none");
		} else {
			formData.images[target.name] = result[0].url
			document.getElementById(element).innerHTML = "Success, Reclick to Resubmit"
			document.getElementById(element).classList.add("bg-success", "text-white");
			document.getElementById(element).classList.remove("bg-danger");
			document.getElementById(target.name).classList.remove("d-none");
			document.getElementById(target.name).href = result[0].url;
		}
	  });
      props.handleUpdateState(formData)
  }

  const handleRegistration = (e) => {
	const { value } = e.target
	const fullUrl = `${option}${ value.replace(/https:\/\//gi,'').replace(/http:\/\//gi,'') }`
	if( (value.length >= 4 && value.includes('www.')) || (value.length === 3 && value.includes('www')) || (value.length === 2 && value.includes('ww')) || (value.length === 1 && value.includes('w')) ) {
		formData.registration = fullUrl
		setUrl( value.replace(/https:\/\//gi,'').replace(/http:\/\//gi,'') )
    } else if (value === '') {
		formData.registration = ''
		setUrl( value.replace(/https:\/\//gi,'').replace(/http:\/\//gi,'') )
    }

	props.handleUpdateState(formData)
  }

  const handleSelectOption = (e) => {
    setOption(e.target.value)
    if (url !== '') {
        const value = `${e.target.value}${url}`
        formData.registration = value
    } else {
        formData.registration = ''
    }
    props.handleUpdateState(formData)
  }

  useEffect(() => {
    if(redirectInfo) {
        formData.images.banner = redirectInfo.images.banner
        document.getElementById("bannerButton").innerHTML = "Success, Reclick to Resubmit"
        document.getElementById("bannerButton").classList.add("bg-success", "text-white");
        document.getElementById("bannerButton").classList.remove("bg-danger");
        document.getElementById("banner").classList.remove("d-none");
        document.getElementById("banner").href = redirectInfo.images.banner

        formData.images.squareIcon = redirectInfo.images.squareIcon
        document.getElementById("squareIconButton").innerHTML = "Success, Reclick to Resubmit"
        document.getElementById("squareIconButton").classList.add("bg-success", "text-white");
        document.getElementById("squareIconButton").classList.remove("bg-danger");
        document.getElementById("squareIcon").classList.remove("d-none");
        document.getElementById("squareIcon").href = redirectInfo.images.squareIcon
        const registration = redirectInfo.registration
        setOption( registration.split('www')[0] )
        setUrl( registration.split('://')[1] )
    }
  }, [])

  return (
    <div className="row">
        <div className="col-12">
            <h5 id="event-banner">Banner <sup className="text-secondary">1170 x 620px</sup></h5>
            <div className="row">
                <div className="col-6">
                    <button onClick={e => { handleLoadImage(e, e.target.id) }} id="bannerButton" name="banner" className="btn orange-bg text-white w-100 px-1">Upload Banner</button>
                </div>
                <div className="col-6">
                    <a className="btn orange-bg text-white w-100 d-none" id="banner" rel="noopener noreferrer" href='https://www.google.com/' target="_blank">Preview</a>
                </div>
            </div>
        </div>
        <div className="col-12 mt-3">
            <h5 id="event-icon">Icon <sup className="text-secondary">200 x 200px</sup></h5>
            <div className="row">
                <div className="col-6">
                    <button onClick={e => { handleLoadImage(e, e.target.id) }} id="squareIconButton" name="squareIcon" className="btn orange-bg text-white w-100 px-1">Upload Icon</button>
                </div>
                <div className="col-6">
                    <a className="btn orange-bg text-white w-100 d-none" id="squareIcon" rel="noopener noreferrer" href='https://www.google.com/' target="_blank">Preview</a>
                </div>
            </div>
        </div>
        <div className="col-12 col-md-12 col-lg-12">
            <h5 className="mt-3">Registration Link <sup className="text-danger">*</sup></h5>
            <div className="row">
                <div className="col-4 col-md-3 pr-0">
                    <select value={option} onChange={handleSelectOption} className="form-control">
                        <option value="http://">http</option>
                        <option value="https://">https</option>
                    </select>
                </div>
                <div className="col-8 col-md-9 pl-0">
                    <input value={url} onChange={handleRegistration} id="registration" name="registration" className="form-control" placeholder="www.google.com"/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default BannersAndRegistration
