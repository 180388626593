import React, { Component } from "react";
import GeneratedApi from "../../functions/generatedApi";
import AddCompany from "./AddCompany";

export class AddCompanyController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      description: "",
    };
  }
  RequiredFields = (field) => {
    if (field.value.trim() === "") {
      document.getElementById(field.id).style.border = "1px solid red";
      return false;
    } else {
      document.getElementById(field.id).style.border = "1px solid lightgray";
      return true;
    }
  };

  HTTPFormatChecker = (el) => {
    if (el.value.trim() !== "") {
      if (el.value.toUpperCase().includes("HTTPS")) return true;
      else {
        document.getElementById(el.id).style.border = "1px solid red";
        return false;
      }
    }
  };
  handleSubmit = (e) => {
    e.preventDefault();
    // document.getElementById('city').innerHTML

    let companyOfficalName = document.getElementById("comapnyOfficalName");
    let companyNickname = document.getElementById("companyNickname"); //Optional
    let companyWebsite = document.getElementById("companyURL");
    let companyAddress1 = document.getElementById("address1");
    let companyAddress2 = document.getElementById("address2"); //Optional
    let companyCity = document.getElementById("city");
    let companyState = document.getElementById("state");
    let companyCountry = document.getElementById("country");
    let companyZipcode = document.getElementById("zipcode");

    this.RequiredFields(companyOfficalName);
    this.RequiredFields(companyWebsite);
    this.RequiredFields(companyAddress1);
    this.RequiredFields(companyCity);
    this.RequiredFields(companyState);
    this.RequiredFields(companyCountry);
    this.RequiredFields(companyZipcode);
    this.HTTPFormatChecker(companyWebsite);

    if (
      !this.RequiredFields(companyOfficalName) ||
      !this.RequiredFields(companyWebsite) ||
      !this.RequiredFields(companyAddress1) ||
      !this.RequiredFields(companyCity) ||
      !this.RequiredFields(companyState) ||
      !this.RequiredFields(companyCountry) ||
      !this.RequiredFields(companyZipcode) ||
      !this.HTTPFormatChecker(companyWebsite)
    ) {
      return null;
    }

    let companyInfo = {
      data: {
        dataId: "-1",
        dataType: "company",
        company_name: companyOfficalName.value,
        company_nickname: companyNickname.value || null,
        company_url: companyWebsite.value,
        company_address: {
          street_1: companyAddress1.value,
          street_2: companyAddress2.value || null,
          city: companyCity.value,
          state: companyState.value,
          zipcode: companyZipcode.value,
          country: companyCountry.value,
        },
        company_sponsor_type: null,
      },
    };

    GeneratedApi("", true, false)
      .post("updateEvent", companyInfo)
      .then((response) => {
        console.log("POST RESULTS OF TESTING AUTHORS^", response);
        // window.location.replace('/')
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  handleDescriptions = (content, editor) => {
    this.setState({ description: content });
  };

  render() {
    return <AddCompany handleSubmit={this.handleSubmit} description={this.state.description} handleDescriptions={this.handleDescriptions} />;
  }
}

export default AddCompanyController;
