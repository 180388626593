const specialRequiredFields = (status, field, type) => {
	if (status) {
		if(type === 'text') {
			field.style.color = "red"; return false;
		}else {
			field.style.border = "1px solid red"
			field.style.borderRadius = "0.5rem"
			return false
		}
		
	} else {
		if(type === 'text') {
			field.style.color = "initial"; return false;
		}else {
			field.style.border = null
			return false
		}
	};
}

export default specialRequiredFields
