import React, { useState } from "react";
import { NavLink as RouterNavLink, Link } from "react-router-dom";
import { Collapse, NavbarBrand, Nav, NavLink, NavItem, NavbarToggler } from "reactstrap";
import logout from "../../functions/logout";

const PcNavbar = (props) => {
  const { position, style, userName } = props;
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="container d-flex">
      <NavbarBrand className="d-flex" tag={Link} to="/">
        <img className="logo" src={position > 272 ? "/images/logo-white.png" : "/images/logo.png"} alt="IoT Nation logo" />
        {userName && (userName.includes("corporate") || userName.includes("pro") || userName.includes("premium")) && (
          <div className="user-type-block ml-2 my-auto d-flex">
            <div className="user-type m-auto">{userName.includes("corporate") ? "CORP" : userName.includes("pro") ? "PRO " : "PREM"}</div>
          </div>
        )}
      </NavbarBrand>

      <NavbarToggler onClick={() => setIsOpen(!isOpen)} />

      <Collapse isOpen={isOpen} navbar>
        <Nav className="w-100" navbar>
          <NavLink activeClassName="selected" className={style.nav_link} tag={RouterNavLink} to="/AddEvent">
            Add Event
          </NavLink>
          <NavLink activeClassName="selected" className={style.nav_link} tag={RouterNavLink} to="/AddCompany">
            Add Company
          </NavLink>
          <a href={process.env.REACT_APP_REDIRECT_URL} target="_newtab" className={`nav-link ${style.nav_link}`} tag={RouterNavLink}>
            Iot Nation
          </a>

          <div className="ml-auto mr-0">
            <NavItem>
              <button className={`nav-link py-2 px-4 ${style.sign_up}`} onClick={() => logout()}>
                Logout
              </button>
            </NavItem>
          </div>
        </Nav>
      </Collapse>
    </div>
  );
};

export default PcNavbar;
