import React, { useState, useEffect } from "react";
import { Navbar } from "reactstrap";
import OutsideClickHandler from "react-outside-click-handler";
import PcNavbar from "./PcNavbar";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import Cookies from "universal-cookie";

import style from "./Header.module.scss";
import AdminHeader from "./AdminHeader";

const cookies = new Cookies();

const Header = (props) => {
  const { userName } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenCopy, setIsOpenCopy] = useState(false);
  const [fullHeight, setFullHeight] = useState(false);
  const [scroll, setScroll] = useState(0);
  const [clickedId, setClickedId] = useState(0);
  const [position, setPosition] = useState(0);

  const closeToggle = () => {
    setIsOpen(false);
    setIsOpenCopy(false);
    setTimeout(() => setFullHeight(false), 500);
  };

  useEffect(() => {
    window.addEventListener("scroll", () => handleScroll(scroll), { passive: true });
    window.addEventListener("scroll", () => positionUpdate());
  }, []);

  const handleClickMenu = (id) => {
    if (id === clickedId) {
      setClickedId(0);
    } else {
      setClickedId(id);
    }
  };
  const handleCloseMenu = () => {
    setClickedId(0);
  };
  const handleScroll = (lastScroll) => {
    if (isOpenCopy) {
      if (window.scrollY < lastScroll) {
        setIsOpen(true);
        setScroll(window.scrollY);
        setFullHeight(true);
        setClickedId(0);
      } else if (window.scrollY > lastScroll) {
        setIsOpen(false);
        setScroll(window.scrollY);
        setFullHeight(false);
        setClickedId(0);
      }
    }
  };
  const positionUpdate = () => {
    let elem = document.querySelector("div");
    let rect = elem.getBoundingClientRect();
    setPosition(rect.top * -1);
  };

  return (
    <Navbar className={`${style.root} ${position > 272 ? `${style.blue_nav} blue-bg` : style.white_nav} px-0 body-bg`} light expand="md">
      <div className="w-100">
        <OutsideClickHandler onOutsideClick={() => closeToggle()}>
          {userName["cognito:groups"] && userName["cognito:groups"].includes("admin") ? (
            <AdminHeader userName={userName["cognito:groups"]} style={style} position={position} />
          ) : (
            <PcNavbar userName={userName["cognito:groups"]} style={style} position={position} />
          )}
        </OutsideClickHandler>
      </div>
    </Navbar>
  );
};

export default Header;
