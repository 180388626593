import React from "react";
import moment from "moment-timezone";
import logout from "./logout";
import getSession from "./getSession";
import Cookies from "universal-cookie";
import * as jwtDecode from "jwt-decode";

const cookies = new Cookies();

export default function logoutChecker(user, saveState) {
  if (user.auth_time + 43200 <= moment().unix()) {
    logout(user);
  } else if (user.exp < moment().unix()) {
    getSession(user, saveState);
    let interval = setInterval(() => {
      if (cookies.get("jwtToken") && jwtDecode(cookies.get("jwtToken"))) {
        getSession(jwtDecode(cookies.get("jwtToken")), saveState);
      } else {
        clearInterval(interval);
      }
    }, (user.exp + 60 - user.iat) * 1000);
  } else {
    setTimeout(() => {
      getSession(user, saveState);
      let interval = setInterval(() => {
        if (cookies.get("jwtToken") && jwtDecode(cookies.get("jwtToken"))) {
          getSession(jwtDecode(cookies.get("jwtToken")), saveState);
        } else {
          clearInterval(interval);
        }
      }, (user.exp + 60 - user.iat) * 1000);
    }, (user.exp + 60 - moment().unix()) * 1000);
  }
  return "";
}
