import React, { useEffect } from 'react'
import moment from 'moment-timezone'
import style from '../AddEvent.module.scss'

const StartTimeAndEndTime = (props) => {
  const { formData } = props

  const getValuesById = (data, whichField) => {
    const getUnixTime = datestring => new Date(datestring).getTime() / 1000;
    let Event_Start_Date = document.getElementById("StartDate");
    let Event_Start_Time = document.getElementById("StartTime");
	let Event_End_Date = "";
	if(whichField === "start"){
		Event_End_Date = document.getElementById("StartDate");
		document.getElementById("EndDate").value = document.getElementById("StartDate").value;
	}else {
		Event_End_Date = document.getElementById("EndDate");
	}
	let Event_End_Time = document.getElementById("EndTime");

    data.date.startTime = getUnixTime(`${Event_Start_Date.value} ${Event_Start_Time.value}`);
    data.date.endTime = getUnixTime(`${Event_End_Date.value} ${Event_End_Time.value}`);

    return ( data )
  }

  const handleTimeChange = (whichField) => {
	props.handleUpdateState({ formData: getValuesById(formData, whichField) })
  }

  useEffect(() => {
	if(formData.date && formData.date.startTime) {
		document.getElementById("StartDate").value = moment.unix(formData.date.startTime).format('YYYY-MM-DD')
    	document.getElementById("StartTime").value = moment.unix(formData.date.startTime).format('hh:mm')
	}
	if(formData.date && formData.date.endTime) {
		document.getElementById("EndDate").value = moment.unix(formData.date.endTime).format('YYYY-MM-DD')
		document.getElementById("EndTime").value = moment.unix(formData.date.endTime).format('hh:mm')
	}
    
  }, [formData])

  return (
    <div>
        {/* start time part */}
        <h5 className="mb-3">Start Date/Time<sup className="text-danger">*</sup></h5>
		<small className="form-text text-muted">{`UTC ${moment().format('Z')} TIME ZONE`}</small>
		<div className="row">
			<div className={style['date-input']}>
				<input onChange={() => handleTimeChange("start")} id="StartDate" name="Start" type="date" className="form-control mb-3" />
			</div>
			<div className={style['time-input']}>
				<input onChange={handleTimeChange} id="StartTime" name="Start" type="time" className="form-control mb-3" />
			</div>
		</div>
		{/* end time part */}
		<h5 className="mb-3">End Date/Time<sup className="text-danger">*</sup></h5>
		<small className="form-text text-muted">{`UTC ${moment().format('Z')} TIME ZONE`}</small>
		<div className="row">
			<div className={style['date-input']}>
				<input onChange={handleTimeChange} id="EndDate" name="End" type="date" className="form-control mb-3" />
			</div>
			<div className={style['time-input']}>
				<input onChange={handleTimeChange} id="EndTime" name="End" type="time" className="form-control mb-3" />
			</div>
		</div>
    </div>
  )
}

export default StartTimeAndEndTime
