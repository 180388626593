import React, { Component } from "react";
import AddCompany from "./AddCompany";
import { Redirect } from "react-router-dom";
import scrollToObject from "../../functions/scrollToObject";
import { NotificationContainer, NotificationManager } from "react-notifications";
import specialRequiredFields from "../../functions/specialRequiredFields";
import specialScrollToObject from "../../functions/specialScrollToObject";
import RequiredFields from "../../functions/RequiredFields";
import GeneratedApi from "../../functions/generatedApi";

export class AddCompanyController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      address: {
        state: "",
        city: "",
        country: "",
      },
    };
  }

  handleUpdateState = (type, input) => {
    const { address } = this.state;
    if (type === "City") {
      address.city = input;
      document.getElementById("city").style.border = "initial";
    } else if (type === "State") {
      address.state = input;
      document.getElementById("state").style.border = "initial";
    } else if (type === "Country") {
      address.country = input;
      document.getElementById("country").style.border = "initial";
    }
    this.setState({ address });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { address } = this.state;
    let companyOfficalName = document.getElementById("comapnyOfficalName");
    let companyNickname = document.getElementById("companyNickname"); //Optional
    let companyWebsite = document.getElementById("companyURL");
    let companyWebsiteType = document.getElementById("companUrl type");
    let companyAddress1 = document.getElementById("address1");
    let companyAddress2 = document.getElementById("address2"); //Optional
    let companyCity = document.getElementById("city");
    let companyState = document.getElementById("state");
    let companyCountry = document.getElementById("country");
    let companyZipcode = document.getElementById("zipcode");

    RequiredFields(companyOfficalName);
    RequiredFields(companyAddress1);
    specialRequiredFields(address.city === "" ? true : false, companyCity, "border");
    specialRequiredFields(address.state === "" ? true : false, companyState, "border");
    specialRequiredFields(address.country === "" ? true : false, companyCountry, "border");
    RequiredFields(companyZipcode);
    specialRequiredFields(companyWebsite.value.includes("www.") ? false : true, companyWebsite, "border");

    if (
      !specialScrollToObject(companyOfficalName.value !== "" ? false : true, companyOfficalName) ||
      !scrollToObject(companyAddress1) ||
      !specialScrollToObject(address.city === "" ? true : false, companyCity, "border") ||
      !specialScrollToObject(address.state === "" ? true : false, companyState, "border") ||
      !specialScrollToObject(address.country === "" ? true : false, companyCountry, "border") ||
      !scrollToObject(companyZipcode) ||
      !specialScrollToObject(companyWebsite.value.includes("www.") ? false : true, companyWebsite)
    ) {
      return null;
    }
    const state = this.props.location.state;
    let companyInfo = {
      data: {
        dataId: state && state.redirectInfo ? state.redirectInfo.dataId : "-1",
        dataType: "company",
        company_name: companyOfficalName.value,
        company_nickname: companyNickname.value || null,
        company_url: `${companyWebsiteType.value}${companyWebsite.value.replace(/https:\/\//gi, "").replace(/http:\/\//gi, "")}`,
        company_address: {
          street_1: companyAddress1.value,
          street_2: companyAddress2.value || null,
          city: companyCity.value,
          state: companyState.value,
          zipcode: companyZipcode.value,
          country: companyCountry.value,
        },
        company_sponsor_type: null,
      },
    };

    GeneratedApi("", true, false)
      .post("updateCompany", companyInfo)
      .then((response) => {
        this.setState({ redirect: true });
      })
      .catch((error) => {
        console.log(error.response);
        NotificationManager.error("Failed to submit form", "Error message");
      });
  };

  render() {
    const { redirect, address } = this.state;
    if (redirect) {
      return (
        <Redirect
          to={{
            pathname: "/UserPanel",
            state: {
              notification: {
                type: "Success message",
                message: "You successfully add company",
                notify: true,
              },
            },
          }}
        />
      );
    }
    return (
      <div>
        <AddCompany state={this.props.location.state} handleSubmit={this.handleSubmit} handleUpdateState={this.handleUpdateState} address={address} />
        <NotificationContainer />
      </div>
    );
  }
}

export default AddCompanyController;
