import React from 'react'
import checkSegment from '../../../functions/checkSegment';
import CustomCheckBox from '../../../components/CustomCheckBox/CustomCheckBox'

const Segment = (props) => {
  const { formData } = props

  const handleCheckSegment = async (e) => {
	const data = checkSegment(e.target, formData)
	props.handleUpdateState(data)
  }
  const otherSigment = formData.eventSegment.filter((item) => item !== 'IoT' && item !== 'AI' && item !== 'Blockchain' && item !== 'AR/VR')

  return (
	<div>
		<h5 id="segment" className="mt-3">Segment(s)</h5>
		<div className="row">
			<div className="col-6 col-md-3">
				<label className="d-flex my-2" htmlFor="IoT">
					<CustomCheckBox
						name="IoT"
						id="IoT"
						onChange={handleCheckSegment}
						checked={formData.eventSegment.filter((item) => item === 'IoT').length > 0}
					/>
					<span className="ml-2">IoT</span>
				</label>
				<label className="d-flex my-2" htmlFor="AI">
					<CustomCheckBox
						name="AI"
						id="AI"
						onChange={handleCheckSegment}
						checked={formData.eventSegment.filter((item) => item === 'AI').length > 0}
					/>
					<span className="ml-2">AI</span>
				</label>
			</div>
			<div className="col-6 col-md-3">
				<label className="d-flex my-2" htmlFor="Blockchain">
					<CustomCheckBox
						name="Blockchain"
						id="Blockchain"
						onChange={handleCheckSegment}
						checked={formData.eventSegment.filter((item) => item === 'Blockchain').length > 0}
					/>
					<span className="ml-2">Blockchain</span>
				</label>
				<label className="d-flex my-2" htmlFor="AR/VR">
					<CustomCheckBox
						name="AR/VR"
						id="AR/VR"
						onChange={handleCheckSegment}
						checked={formData.eventSegment.filter((item) => item === 'AR/VR').length > 0}
					/>
					<span className="ml-2">AR/VR</span>
				</label>
			</div>
		</div>
		<div className="row">
			<div className="col-12 col-md-6">
				<div className="form-group">
					<input value={otherSigment && otherSigment[0] ? otherSigment[0] : ''} onChange={handleCheckSegment} name="Other Segment" type="text" className="form-control" placeholder="Other Segment"/>
				</div>
			</div>
		</div>
	</div>
  )
}

export default Segment
