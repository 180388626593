const previewArray = (data) => {
    var array = []
    array.push(encodeURIComponent('startTime') + "=" + encodeURIComponent(data.date.startTime) || '')
    array.push(encodeURIComponent('endTime') + "=" + encodeURIComponent(data.date.endTime) || '')
    array.push(encodeURIComponent('eventType') + "=" + encodeURIComponent(data.eventType) || '')
    array.push(encodeURIComponent('title') + "=" + encodeURIComponent(data.title.trim()) || '')
    array.push(encodeURIComponent('website') + "=" + encodeURIComponent(data.website) || '')
    array.push(encodeURIComponent('registration') + "=" + encodeURIComponent(data.registration) || '')
    array.push(encodeURIComponent('organizers') + "=" + encodeURIComponent(''))
    array.push(encodeURIComponent('discountCode') + "=" + encodeURIComponent(data.discountCode) || '')
    array.push(encodeURIComponent('street1') + "=" + encodeURIComponent(data.location.street1) || '')
    array.push(encodeURIComponent('city') + "=" + encodeURIComponent(data.location.city) || '')
    array.push(encodeURIComponent('stateCode') + "=" + encodeURIComponent(data.location.stateCode) || '')
    array.push(encodeURIComponent('zipCode') + "=" + encodeURIComponent(data.location.zipCode) || '')
    array.push(encodeURIComponent('country') + "=" + encodeURIComponent(data.location.country) || '')
    array.push(encodeURIComponent('banner') + "=" + encodeURIComponent(data.images.banner) || '')
    array.push(encodeURIComponent('shortDescription') + "=" + encodeURIComponent(data.descriptions.shortDescription) || '')
    array.push(encodeURIComponent('longDescription') + "=" + encodeURIComponent(data.descriptions.longDescription) || '')
    array.push(encodeURIComponent('sponsors') + "=" + encodeURIComponent(''))

    array.join("&")
console.log('fg',data.speakers)
    data.speakers && data.speakers.map((item, key) => 
      item.speaker ?
        array.push(encodeURIComponent(`SpImg${key}`) 
          + item.speaker.image ? `=Img${encodeURIComponent(item.speaker.image)}` : ''
          + item.speaker.firstName ? `FN${encodeURIComponent(item.speaker.firstName)}` : ''
          + item.speaker.lastName ? `LN${encodeURIComponent(item.speaker.lastName)}` : ''
          + item.speaker.linkedin ? `LI${encodeURIComponent(item.speaker.linkedin)}` : ''
          + item.speaker.title ? `T${encodeURIComponent(item.speaker.title)}` : ''
          + item.speaker.biography ? `B${encodeURIComponent(item.speaker.biography)}` : ''
          + item.speaker.company && item.speaker.company.name ? `CN${encodeURIComponent(item.speaker.company.name)}` : '' + 'L' )
      :''
    )

    // data.organizers && data.organizers.map((item, key) => 
    //   array.push(encodeURIComponent(`Organizer${key}`) + '=logo' + encodeURIComponent(item.info.logo) + 'id' + 
    //   encodeURIComponent(item.info.id) + 'L' )
    // )
    data.tags.verticals && data.tags.verticals.map((item, key) => 
      array.push(encodeURIComponent(`verticals${key}`) + "=" + encodeURIComponent(item))
    )
    data.tags.horizontals && data.tags.horizontals.map((item, key) => 
      array.push(encodeURIComponent(`horizontals${key}`) + "=" + encodeURIComponent(item))
    )
    
    return (
        array
    )
}

export default previewArray
