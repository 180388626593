import React, { useState, useEffect } from 'react'

const Media = (props) => {
  const { formData, redirectInfo } = props
  const [ options, setOption ] = useState({
	website: 'https://',
	blog: 'https://',
	twitter: 'https://',
	linkedin: 'https://',
	facebook: 'https://',
	youtube: 'https://'
  })

  const handleSocialLinks = (e) => {
	const { value } = e.target
	if( (value.length >= 4 && value.includes('www.')) || (value.length === 3 && value.includes('www')) || (value.length === 2 && value.includes('ww')) || (value.length === 1 && value.includes('w')) ) {
		formData.socialLinks[e.target.name] = `${options[e.target.name]}${e.target.value.replace(/https:\/\//gi,'').replace(/http:\/\//gi,'')}`
	} else if(value === '') {
		formData.socialLinks[e.target.name] = ''
	}
	props.handleUpdateState(formData)
  }

  const handlewebsite = (e) => {
	const { value } = e.target
	if( (value.length >= 4 && value.includes('www.')) || (value.length === 3 && value.includes('www')) || (value.length === 2 && value.includes('ww')) || (value.length === 1 && value.includes('w')) ) {
		formData[e.target.name] = `${options.website}${e.target.value.replace(/https:\/\//gi,'').replace(/http:\/\//gi,'')}`
	} else if(value === '') {
		formData[e.target.name] = ''
	}
	
	props.handleUpdateState(formData)
  }

  const handleSelectOption = (e) => {
	const option = options
	option[e.target.name] = e.target.value
    setOption(option)
    if (e.target.name === 'website') {
		const url = formData.website.replace(/https:\/\//gi,'').replace(/http:\/\//gi,'')
		const value = `${e.target.value}${url}`
		if(formData.website !== '') {
			formData.website = value
		} else {
			formData.website = ''
		}
    } else {
		const url = formData.socialLinks[e.target.name].replace(/https:\/\//gi,'').replace(/http:\/\//gi,'')
		const value = `${e.target.value}${url}`
		if(formData.socialLinks[e.target.name] !== '') {
			formData.socialLinks[e.target.name] = value
		} else {
			formData.socialLinks[e.target.name] = ''
		}	
	}
	props.handleUpdateState(formData)
  }

  useEffect(() => {
	if(redirectInfo) {
		setOption({
			website: redirectInfo.website && redirectInfo.website.split('www')[0],
			blog: redirectInfo.socialLinks.blog && redirectInfo.socialLinks.blog.split('www')[0],
			twitter: redirectInfo.socialLinks.twitter && redirectInfo.socialLinks.twitter.split('www')[0],
			linkedin: redirectInfo.socialLinks.linkedin && redirectInfo.socialLinks.linkedin.split('www')[0],
			facebook: redirectInfo.socialLinks.facebook && redirectInfo.socialLinks.facebook.split('www')[0],
			youtube: redirectInfo.socialLinks.youtube && redirectInfo.socialLinks.youtube.split('www')[0]
		})

	}
  }, [])

  return (
  <div className="col-12 col-lg-6">
        <h4 className="mt-4 text-center">Media (Optional)</h4>

        <h5 className="my-3 mb-0">Website Link</h5>
        <div className="row">
        	<div className="col-4 col-xl-3 pr-0">
				<select value={options.website} onChange={handleSelectOption} name="website" className="form-control">
					<option value="http://">http</option>
					<option value="https://">https</option>
				</select>
        	</div>
			<div className="col pl-0">
				<input value={formData.website&&formData.website.split('://')[1]} onChange={handlewebsite} name="website"className="form-control" placeholder="www.google.com"/>
			</div>
        </div>

        <h5 className="my-3 mb-0">Blog Link</h5>
		<div className="row">
			<div className="col-4 col-xl-3 pr-0">
				<select value={options.blog} onChange={handleSelectOption} name="blog" className="form-control">
					<option value="http://">http</option>
					<option value="https://">https</option>
				</select>
        	</div>
			<div className="col pl-0">
				<input value={formData.socialLinks.blog&&formData.socialLinks.blog.split('://')[1]} onChange={handleSocialLinks} name="blog" className="form-control" placeholder="www.google.com"/>
			</div>
		</div>
        
        <h5 className="my-3 mb-0">Twitter Link</h5>
		<div className="row">
			<div className="col-4 col-xl-3 pr-0">
				<select value={options.twitter} onChange={handleSelectOption} name="twitter" className="form-control">
					<option value="http://">http</option>
					<option value="https://">https</option>
				</select>
        	</div>
			<div className="col pl-0">
				<input value={formData.socialLinks.twitter&&formData.socialLinks.twitter.split('://')[1]} onChange={handleSocialLinks} name="twitter" className="form-control" placeholder="www.google.com"/>
			</div>
		</div>

        <h5 className="my-3 mb-0">Linkedin Link</h5>
		<div className="row">
			<div className="col-4 col-xl-3 pr-0">
				<select value={options.linkedin} onChange={handleSelectOption} name="linkedin" className="form-control">
					<option value="http://">http</option>
					<option value="https://">https</option>
				</select>
        	</div>
			<div className="col pl-0">
				<input value={formData.socialLinks.linkedin&&formData.socialLinks.linkedin.split('://')[1]} onChange={handleSocialLinks} name="linkedin" className="form-control" placeholder="www.google.com"/>
			</div>
		</div>

        <h5 className="my-3 mb-0">Facebook Link</h5>
		<div className="row">
			<div className="col-4 col-xl-3 pr-0">
				<select value={options.facebook} onChange={handleSelectOption} name="facebook" className="form-control">
					<option value="http://">http</option>
					<option value="https://">https</option>
				</select>
        	</div>
			<div className="col pl-0">
				<input value={formData.socialLinks.facebook&&formData.socialLinks.facebook.split('://')[1]} onChange={handleSocialLinks} name="facebook" className="form-control" placeholder="www.google.com"/>
			</div>
		</div>
    
        <h5 className="my-3 mb-0">Youtube Link</h5>
		<div className="row">
			<div className="col-4 col-xl-3 pr-0">
				<select value={options.youtube} onChange={handleSelectOption} name="youtube" className="form-control">
					<option value="http://">http</option>
					<option value="https://">https</option>
				</select>
        	</div>
			<div className="col pl-0">
				<input value={formData.socialLinks.youtube&&formData.socialLinks.youtube.split('://')[1]} onChange={handleSocialLinks} name="youtube" className="form-control" placeholder="www.google.com"/>
			</div>
		</div>
    </div>
  )
}

export default Media
