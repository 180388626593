import React, { Component } from "react";
import { NotificationManager } from "react-notifications";
import GeneratedApi from "../../../functions/generatedApi";
import RequiredFields from "../../../functions/RequiredFields";
import validEmail from "../../../functions/validEmail";
import style from "../AddEvent.module.scss";

export default class AddSpeaker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      speakers: [],
    };
  }

  handleLoadImage = (e, element, id) => {
    e.preventDefault();
    const { target } = e;
    const data = this.state.speakers;
    window.cloudinary.openUploadWidget(
      { height: 200, width: 150, crop: "imagga_scale", cloud_name: "allpurposeflour", upload_preset: "t5rsav4b" },
      function (error, result) {
        if (error) {
          data[id].image = "";
          document.getElementById(element).innerHTML = "Unsuccess, Reclick to Resubmit";
          document.getElementById(element).classList.add("bg-danger", "text-white");
          document.getElementById(element).classList.remove("bg-success");
          document.getElementById(target.name).classList.add("d-none");
        } else {
          data[id].image = result[0].url;
          document.getElementById(element).innerHTML = "Success, Reclick to Resubmit";
          document.getElementById(element).classList.add("bg-success", "text-white");
          document.getElementById(element).classList.remove("bg-danger");
          document.getElementById(target.name).classList.remove("d-none");
          document.getElementById(target.name).href = result[0].url;
        }
      }
    );
    this.setState({ speakers: data });
  };

  handleAddSpeaker = () => {
    let newSpeaker = {
      dataId: "-1",
      dataType: "contact",
      speakerType: "",
      firstName: "",
      lastName: "",
      title: "",
      email: {
        workEmail: "",
      },
      biography: "",
      linkedin: "",
      image: "",
      address: {
        street1: "225 Amsterdam Ave",
        street2: "Suite 6",
        city: "New York",
        state: "New York",
        stateCode: "NY",
        country: "United States of America",
        countryCode: "USA",
        zipCode: "10023",
        region: "Greater New York area",
      },
      company: {
        name: "",
      },
    };
    this.setState({ speakers: [...this.state.speakers, newSpeaker] });
  };

  handleInput = (id, e, name) => {
    const data = this.state.speakers;
    data[id][name] = e.target.value;
    this.setState({ speakers: data });
  };

  handleTwoLevelInput = (name, secondName, id, e) => {
    const data = this.state.speakers;
    data[id][name][secondName] = e.target.value;
    this.setState({ speakers: data });
  };

  handleDeleteSpeaker = async (id) => {
    const data = this.state.speakers;
    await data.splice(id, 1);
    await this.setState({ speakers: [...data] });
  };

  makeSuccess = (id) => {
    document.getElementById(`submit-${id}`).classList.add("bg-success", "text-white");
    document.getElementById(`submit-${id}`).classList.remove("bg-danger");
    document.getElementById(`speaker-block${id}`).classList.add("disable-block");
  };

  handleSubmitSpeaker = (id) => {
    let firstName = document.getElementById(`speaker-firstName${id}`);
    let lastName = document.getElementById(`speaker-lastName${id}`); //Optional
    let speakerCompany = document.getElementById(`speaker-company${id}`);
    let email = document.getElementById(`speaker-email${id}`);

    RequiredFields(firstName);
    RequiredFields(lastName);
    RequiredFields(speakerCompany);
    validEmail(email);

    if (!RequiredFields(firstName) || !RequiredFields(lastName) || !RequiredFields(speakerCompany) || !validEmail(email)) {
      return null;
    }
    const { handleUpdateState, formData } = this.props;

    GeneratedApi("", true, false)
      .post("updateContact", { data: this.state.speakers[id] })
      .then((response) => {
        response = response.data;
        formData.speakers = [...formData.speakers, response];
        handleUpdateState(formData);
        this.makeSuccess(id);
        NotificationManager.success("Speaker successfully submitted !", "Success message");
      })
      .catch((error) => {
        console.log(error.response);
        NotificationManager.error("Failed to submit Speaker", "Error message");
      });
  };

  render() {
    return (
      <div className="row mb-5">
        <div className="col-12">
          <h4 className="text-center my-4">Speakers</h4>
        </div>
        {this.state.speakers.map((item, key) => (
          <div key={key} id={`speaker-block${key}`} className="col-12">
            <div className="row mx-0 border pb-4 rounded">
              <div className={`p-2 w-100 h4 ${style["blue-background"]}`}>Speaker {key + 1}</div>
              <div className="col-12 col-md-6 mt-3">
                <h5>
                  First Name<sup className="text-danger">*</sup>
                </h5>
                <input
                  value={item.firstName}
                  onChange={(e) => this.handleInput(key, e, "firstName")}
                  id={`speaker-firstName${key}`}
                  type="text"
                  className="form-control"
                  placeholder="First Name"
                />
              </div>
              <div className="col-12 col-md-6 mt-3">
                <h5>
                  Last Name<sup className="text-danger">*</sup>
                </h5>
                <input
                  value={item.lastName}
                  onChange={(e) => this.handleInput(key, e, "lastName")}
                  id={`speaker-lastName${key}`}
                  type="text"
                  className="form-control"
                  placeholder="Last Name"
                />
              </div>
              <div className="col-12 col-md-6 mt-3">
                <h5>Job Title</h5>
                <input
                  value={item.title}
                  onChange={(e) => this.handleInput(key, e, "title")}
                  id="speaker-title"
                  type="text"
                  className="form-control"
                  placeholder="Job Title"
                />
              </div>
              <div className="col-12 col-md-6 mt-3">
                <h5>
                  Company Name<sup className="text-danger">*</sup>
                </h5>
                <input
                  onChange={(e) => this.handleTwoLevelInput("company", "name", key, e)}
                  id={`speaker-company${key}`}
                  type="text"
                  className="form-control"
                  placeholder="Company Name"
                />
              </div>
              <div className="col-12 col-md-6 mt-3">
                <h5>Email</h5>
                <input
                  onChange={(e) => this.handleTwoLevelInput("email", "workEmail", key, e)}
                  id={`speaker-email${key}`}
                  type="email"
                  className="form-control"
                  placeholder="Email"
                />
              </div>
              <div className="col-12 col-md-6 mt-3">
                <h5>Speaker Type</h5>
                <select
                  value={item.speakerType}
                  onChange={(e) => this.handleInput(key, e, "speakerType")}
                  className="form-control"
                  name="speakerType"
                  id="speakerType"
                >
                  <option value=""></option>
                  <option value="Speaker">Speaker</option>
                  <option value="Panelist">Panelist</option>
                  <option value="Moderator">Moderator</option>
                  <option value="Keynote">Keynote</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="col-12 col-md-6 mt-3">
                <h5>Linkedin</h5>
                <input
                  onChange={(e) => this.handleInput(key, e, "linkedin")}
                  id="speaker-linkedin"
                  type="text"
                  className="form-control"
                  placeholder="Linkedin"
                />
              </div>
              <div className="col-12 mt-3">
                <h5>Picture</h5>
                <div className="row">
                  <div className="col-6">
                    <button
                      onClick={(e) => {
                        this.handleLoadImage(e, e.target.id, key);
                      }}
                      id={`speakerBannerButton${key}`}
                      name={`speakerBanner${key}`}
                      className="btn orange-bg text-white w-100 px-1"
                    >
                      Upload Picture
                    </button>
                  </div>
                  <div className="col-6">
                    <a
                      className="btn orange-bg text-white w-100 d-none"
                      id={`speakerBanner${key}`}
                      rel="noopener noreferrer"
                      href="https://www.google.com/"
                      target="_blank"
                    >
                      Preview
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-12 mt-3 d-flex">
                <div className="mx-auto">
                  <button onClick={() => this.handleSubmitSpeaker(key)} id={`submit-${key}`} className="btn orange-bg text-white mr-2 mb-2 mb-xs-0">
                    Submit Speaker
                  </button>
                  <button onClick={() => this.handleDeleteSpeaker(key)} id={`delete-${key}`} className="btn btn-danger text-white mb-2 mb-xs-0">
                    Delete Speaker
                  </button>
                </div>
              </div>
            </div>
            <hr className="mt-4" />
          </div>
        ))}
        <div className="col-12 mt-3 d-flex">
          <button onClick={() => this.handleAddSpeaker()} className="btn px-5 orange-bg text-white mx-auto">
            Add Speaker
          </button>
        </div>
        
      </div>
    );
  }
}
