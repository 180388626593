import React, { Component } from "react";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import ManualSpinner from "../../components/ManualSpinner/ManualSpinner";

// import './Login.scss';
import style from "./Login.module.scss";

const cookies = new Cookies();

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      confirm: false, //For MFA
    };
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const userPool = new AmazonCognitoIdentity.CognitoUserPool({
      UserPoolId: process.env.REACT_APP_COGNITO_POOLID,
      ClientId: process.env.REACT_APP_COGNITO_CLIENTID,
    });

    var authenticationData = {
      Username: this.state.email,
      Password: this.state.password,
    };

    var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
      Username: this.state.email,
      Pool: userPool,
    });
    this.props.setLoading(true);

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        let decodedValue = JSON.parse(window.atob(result.idToken.jwtToken.split(".")[1]));
        const token = result.idToken.jwtToken;
        cookies.set("refreshToken", result.refreshToken.token, {
          path: "/",
          domain: process.env.REACT_APP_JSON_TYPE === "local" ? "localhost" : "iotnation.com",
          expires: new Date(Date.now() + 86400000),
          sameSite: false,
        });
        cookies.set("jwtToken", token.split("&")[0], {
          path: "/",
          domain: process.env.REACT_APP_JSON_TYPE === "local" ? "localhost" : "iotnation.com",
          expires: new Date(Date.now() + 86400000),
          sameSite: false,
        });
        let key;
        let obj = window.localStorage;
        for (key in obj) {
          if (obj.hasOwnProperty(key)) {
            if (key.includes("CognitoIdentityServiceProvider") && !key.includes(".refreshToken")) {
              if (key.includes("idToken")) {
                cookies.set(process.env.REACT_APP_JSON_TYPE === "production" ? "PSId" : "DSId", `${key.split(".")[1]}.${key.split(".")[2]}`, {
                  path: "/",
                  domain: process.env.REACT_APP_JSON_TYPE === "local" ? "localhost" : "iotnation.com",
                  expires: new Date(Date.now() + 86400000),
                  sameSite: false,
                });
              } else {
                cookies.set(key, obj[key], {
                  path: "/",
                  domain: process.env.REACT_APP_JSON_TYPE === "local" ? "localhost" : "iotnation.com",
                  expires: new Date(Date.now() + 86400000),
                  sameSite: false,
                });
              }
            }
          }
        }

        if (decodedValue["cognito:groups"]) {
          if (decodedValue["cognito:groups"].includes("admin")) {
            window.location.pathname = "/AdminPanel";
          } else {
            window.location.pathname = "/UserPanel";
          }
        } else {
          window.location.pathname = "/UserPanel";
        }
      },

      onFailure: (err) => {
        console.log(err);
        // NotificationManager.error(err.message);
        alert(err.message);
        this.props.setLoading(false);
      },
    });
  };
  render() {
    const { loginLoading } = this.props;
    const { email, password } = this.state;

    return (
      <div className={`container ${style.root}`}>
        <div className={`col-sm-10 offset-sm-1 offset-md-2 col-md-8 offset-lg-2 mt-5 pt-5 ${loginLoading ? "blurred-box" : ""}`}>
          <div className={`${style.rectangle} rounded-lg`}>
            <div className="row">
              <div className="col-12">
                <div className={`${style.login_btn} font-s-16 blue-bg rounded-bottom-lg d-flex text-white py-3`}>
                  <p className="m-auto">Login</p>
                </div>
              </div>
            </div>
            <div className={`${style.form_block} py-4`}>
              <div className="row py-md-5 py-sm-4">
                <div className="col-10 offset-1">
                  <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                      <input
                        value={email}
                        name="email"
                        required
                        type="text"
                        className="form-control"
                        placeholder="Email address"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        value={password}
                        name="password"
                        required
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <Link to="/reset-password">Forgot password?</Link>
                    </div>
                    <div className="form-group" style={{ display: "flex", justifyContent: "center" }}>
                      <button className={`${loginLoading ? "disabled" : ""} btn btn-primary py-2 px-5`} type="submit">
                        Login
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {loginLoading && <ManualSpinner />}
      </div>
    );
  }
}

export default Login;
