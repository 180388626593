import React from 'react'
import { Link } from 'react-router-dom';

import './Breadcrumbs.scss';

const Breadcrumbs = (props) => {
  const { midText, midFilter, midPath, last, homePath } = props;
  return (
    <div className="container Breadcrumbs">
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to={homePath}>Home</Link></li>
                {
                    midPath && <Link to={{pathname: midPath,  state:{ midFilter } }} >{ midText }</Link>
                }
                {
					last && <li className="breadcrumb-item active" aria-current="page">{ last }</li>
				}
            </ol>
        </nav>
    </div>
  )
}

export default Breadcrumbs