import React from 'react'
import tinymce from 'tinymce/tinymce'
import { Editor } from '@tinymce/tinymce-react';

const Descriptions = (props) => {
  const { formData } = props
  const handleDescriptions = (content, editor) => {
    formData.descriptions[editor.targetElm.name] = content
    props.handleUpdateState(formData)
  }

  return (
  <div>
    <h5 className="mt-3">Short Description</h5>
		<div id="shortDescription">
			<Editor
				initialValue="<p>200 words or less</p>"
				init={{
					height: 300,
					menubar: false,
					plugins: [
						'advlist autolink lists link image charmap print preview anchor textcolor',
						'searchreplace visualblocks code fullscreen',
						'insertdatetime media table paste code help wordcount'
					],
					toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
				}}
				onEditorChange={handleDescriptions}
				textareaName="shortDescription"
				value={formData.descriptions.shortDescription}
			/>
		</div>

    <h5 className="mt-3">Full Description<sup className="text-danger">*</sup></h5>
    <div id="longDescription">
      <Editor
          initialValue="<p></p>1,000 words or less</p>"
          init={{
              height: 300,
              menubar: false,
              plugins: [
                  'advlist autolink lists link image charmap print preview anchor textcolor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
              ],
              toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
          }}
          onEditorChange={handleDescriptions}
          textareaName="longDescription"
          value={formData.descriptions.longDescription}
      />
    </div>
  </div>
  )
}

export default Descriptions