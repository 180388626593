import axios from "axios";
import Cookies from "universal-cookie";
import * as jwtDecode from "jwt-decode";

axios.defaults.headers.post["Content-Type"] = "application/json";
const cookies = new Cookies();

export default function GeneratedApi(token = "", auth = false, apiKey = false) {
  const Token = token ? token : cookies.get("jwtToken");
  const baseUrl = process.env.REACT_APP_BASE_URL;

  let instance = axios.create({
    baseURL: Token && auth ? baseUrl + "auth/" : baseUrl,
    timeout: 10000,
    headers: {
      Accept: "application/json",
    },
  });

  if (Token) instance.defaults.headers.common["Authorization"] = "Bearer " + Token;
  if (Token && apiKey) {
    instance.defaults.headers.common["x-api-key"] = jwtDecode(Token)["custom:api_key_mutable"];
  } else {
    delete instance.defaults.headers.common["x-api-key"];
  }

  return instance;
}
