import React from "react";
import { NavLink as RouterNavLink, Link } from "react-router-dom";
import { Collapse, NavbarBrand, Nav, NavItem } from "reactstrap";
import logout from "../../functions/logout";

const AdminHeader = (props) => {
  const { position, style } = props;
  return (
    <div className="container d-none d-md-flex">
      <NavbarBrand className="d-flex" tag={Link} to="/">
        <img className="logo" src={position > 272 ? "/images/logo-white.png" : "/images/logo.png"} alt="IoT Nation logo" />
      </NavbarBrand>
      <Collapse isOpen={true} navbar>
        <Nav className="w-100" navbar>
          <a
            href={process.env.REACT_APP_REDIRECT_URL}
            target="_newtab"
            activeClassName="selected"
            className={`nav-link ${style.nav_link}`}
            tag={RouterNavLink}
          >
            Iot Nation
          </a>
          <div className="ml-auto mr-0">
            <NavItem>
              <button className={`nav-link py-2 px-4 ${style.sign_up}`} onClick={() => logout()}>
                Logout
              </button>
            </NavItem>
          </div>
        </Nav>
      </Collapse>
    </div>
  );
};

export default AdminHeader;
