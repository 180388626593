const specialScrollToObject = (status, field) => {
	if (status) {
		const scroll = function(c, a, b, i) {
			i++; if (i > 30) return;
			c.scrollTop = a + (b - a) / 30 * i;
			setTimeout(function(){ scroll(c, a, b, i); }, 20);
		}
		scroll(document.firstElementChild, window.pageYOffset, (window.pageYOffset + field.getClientRects()[0].top - 58 - 20), 0);
		return false;
	} else {
		return true
	}
}

export default specialScrollToObject
