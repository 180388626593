import React, {useEffect} from 'react'

const Atendee = (props) => {
  const { formData, redirectInfo } = props

  const addAttendee = (data) => {
    data.attendeeTypes = []
    let Data_Scientists = document.getElementById("Data Scientists").value;
    let Full_Stack_Engineers = document.getElementById("Full Stack Engineers").value;
    let Recruiters = document.getElementById("Recruiters").value;
    //  data.attendeeTypes = [Data_Scientists, Full_Stack_Engineers, Recruiters]
    data.attendeeTypes = Data_Scientists ? [...data.attendeeTypes, Data_Scientists] : data.attendeeTypes
    data.attendeeTypes = Full_Stack_Engineers ? [...data.attendeeTypes, Full_Stack_Engineers] : data.attendeeTypes
    data.attendeeTypes = Recruiters ? [...data.attendeeTypes, Recruiters] : data.attendeeTypes

    return ( data )
  }

  const handleAttendee = (e) => {
	  props.handleUpdateState({ formData: addAttendee(formData) })
  }

  useEffect(() => {
    if(redirectInfo && redirectInfo.attendeeTypes && redirectInfo.attendeeTypes.length > 0) {
      document.getElementById("Data Scientists").value = redirectInfo.attendeeTypes[0]
      document.getElementById("Full Stack Engineers").value = redirectInfo.attendeeTypes.length > 1 ? redirectInfo.attendeeTypes[1] : ''
      document.getElementById("Recruiters").value = redirectInfo.attendeeTypes.length > 2 ? redirectInfo.attendeeTypes[2] : ''
    }
  }, [])

  return (
    <div className="col-12 col-lg-6">
        <h4 className="mt-4 text-center">Attendee(s)</h4>
        <input onChange={handleAttendee} id="Data Scientists" type="text" className="form-control mb-3" placeholder="e.g. Data Scientists"/>
        <input onChange={handleAttendee} id="Full Stack Engineers" type="text" className="form-control mb-3" placeholder="e.g. Full Stack Engineers"/>
        <input onChange={handleAttendee} id="Recruiters" type="text" className="form-control mb-3" placeholder="e.g. Recruiters"/>
    </div>
  )
}

export default Atendee
