import React from 'react'
import { Modal, ModalHeader, ModalBody} from 'reactstrap';

export const CustomModal = ({ handleModal, modal, className, modalBody, modalHeader }) => {

  return (
	<Modal isOpen={modal} toggle={() => handleModal(false, '')} className={className} centered={true}>
		<ModalHeader toggle={() => handleModal(false, '')}>
			{ modalHeader }
		</ModalHeader>
		<ModalBody style={{backgroundColor: "white"}}>
			{ modalBody }
		</ModalBody>
	</Modal>
  )
}

