import React from 'react'

const Advertising = (props) => {
  const { formData } = props

  const handleYesNo = (e) => {
	if (e.target.value === 'true') {
		formData[e.target.id] = true
	} else if (e.target.value === 'false') {
		formData[e.target.id] = false
	} else {
		formData[e.target.id] = false
	}
    props.handleUpdateState(formData)
  }
  return (
    <div>
        <h4 className="my-3 text-center">Advertising</h4>
		<div className="row">
			<div className="col-12 col-md-6">
				<h5 className="my-3">Feature this event on our site?<sup className="text-danger">*</sup></h5>
				<select value={formData.featuredDataOptIn} onChange={handleYesNo} className="form-control" name="format" id="featuredDataOptIn">
					<option value=''></option>
					<option value={true}>Yes</option>
					<option value={false}>No</option>
				</select>
			</div>
			<div className="col-12 col-md-6">
				<h5 className="my-3">Advertise this event on our site?<sup className="text-danger">*</sup></h5>
				<select value={formData.advertisementOptIn} onChange={handleYesNo} className="form-control" name="format" id="advertisementOptIn">
					<option value=''></option>
					<option value={true}>Yes</option>
					<option value={false}>No</option>
				</select>
			</div>
		</div>
    </div>
  )
}

export default Advertising
