import React, { useState } from 'react'
import { FaTrashAlt } from 'react-icons/fa';
import { FaEdit } from 'react-icons/fa';
import style from './Table.module.scss'
import ManualSpinner from '../../../components/ManualSpinner/ManualSpinner';
import { Link } from 'react-router-dom';
import { CustomModal } from '../../../components/Modal/CustomModal';
import previewArray from '../../../functions/previewArray';

const Table = (props) => {
  const { responseArray, handleDelete, handleUpdateTable, handleSort, spinner } = props.tableProps;
  const [ modal, setModal ] = useState(false)
  const [ dataItem, setItem ] = useState('')

  const showTitle = (dataType, item) => {
	switch (dataType) {
		case "event":
			return ( item.title )
		case "contact":
			return ( `${item.firstName} ${item.lastName}` )
		case "company":
			return ( item.company_name )
		default:
			console.log("Something has gone horribly wrong")
	}
  }

  const handleDeletePrompt  = (item) => {
	const deleteRow = window.confirm("Are you sure wannt delete?")
	if(deleteRow) {
		handleDelete(item)
	}
  }
  const getTimeReadableByHumans = datestring => {
	let a = new Date(datestring * 1000);
	let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	let year = a.getFullYear();
	let month = months[a.getMonth()];
	let date = a.getDate();
	let hour = a.getHours();
	let min = a.getMinutes();
	if (min < 10) min = "0" + min;

	let exactDate = date + ' ' + month + ' ' + year;
	let exactTime = hour + ':' + min;

	let time = {
		dayof: exactDate,
		timeof: exactTime
	}

	return time;
  }
  const handleModal = (status, item) => {
	setItem(item)
	setModal(status)
  }
  const modalBody = (info) => {
	return(
		<div>
			<div className="d-flex pt-1 pb-3 px-1 border-bottom">
				<div> Company Name </div>
				<div className="ml-auto mr-0"> {info.company_name} </div>
			</div>
			<div className="d-flex py-3 px-1 border-bottom">
				<div> Company Nickname </div>
				<div className="ml-auto mr-0"> {info.company_nickname} </div>
			</div>
			<div className="d-flex py-3 px-1 border-bottom">
				<div> Company Url </div>
				<div className="ml-auto mr-0"> {info.company_url} </div>
			</div>
			<div className="d-flex py-3 px-1 border-bottom">
				<div> City </div>
				<div className="ml-auto mr-0"> {info.company_address && info.company_address.city} </div>
			</div>
			<div className="d-flex py-3 px-1 border-bottom">
				<div> Country </div>
				<div className="ml-auto mr-0"> {info.company_address && info.company_address.country} </div>
			</div>
			<div className="d-flex py-3 px-1 border-bottom">
				<div> State </div>
				<div className="ml-auto mr-0"> {info.company_address && info.company_address.state} </div>
			</div>
			<div className="d-flex py-3 px-1 border-bottom">
				<div> Street 1 </div>
				<div className="ml-auto mr-0"> {info.company_address && info.company_address.street_1} </div>
			</div>
			<div className="d-flex py-3 px-1 border-bottom">
				<div> Street 2 </div>
				<div className="ml-auto mr-0"> {info.company_address && info.company_address.street_2} </div>
			</div>
			<div className="d-flex pt-3 pb-1 px-1">
				<div> Zip Code </div>
				<div className="ml-auto mr-0"> {info.company_address && info.company_address.zipcode} </div>
			</div>
		</div>
	)
  }
  const modalHeader = (info) => {
	return (
		<div className="h3 text-center">
			{info.dataType === 'company' ? 'Company info' : 'Event info'}
		</div>
	)
  }
  const fakeHandle = () => {}
  const handlePreview = (info) => {
	const array = previewArray(info)
	window.open(`${process.env.REACT_APP_REDIRECT_URL}/events/preview/?q=${escape(array)}`, '_blank');
  }
  if(spinner) {
	return (
		<ManualSpinner />
	)
  }
  return (
	<div className={`${style.root} container mb-5`}>
		<table className="w-100 border border-r-4 bg-white">
			<thead className="w-100">
				<tr className="row mx-0">
					<th className="col-2 py-3 px-4 font-s-13 black-color-2">SUBMISSION TITLE</th>
					<th onClick={() =>handleSort()} className="col-2 py-3 px-4 font-s-13 black-color-2 sort pointer">DATE</th>
					<th className="col-2 py-3 px-4 font-s-13 black-color-2">TIME</th>
					<th className="col-2 py-3 px-4 font-s-13 black-color-2 pointer">CONTENT TYPE</th>
					<th className="col-2 py-3 px-4 font-s-13 black-color-2 pointer">STATUS</th>
					<th className="col-2 py-3 px-4 font-s-13 black-color-2">ACTION</th>
				</tr>
			</thead>
			<CustomModal
				handleModal={handleModal}
				modal={modal}
				modalBody={modalBody(dataItem)}
				modalHeader={modalHeader(dataItem)}
			/>
			<tbody>
				{
					responseArray.map((item, key) => (
						<tr key={`userTable-${key}`} className="row mx-0 blue-color-2">
							<th className="col-2 py-3 my-auto hover-blue pointer px-4">
								<button 
									className="remove-btn-style"
									onClick={() => { item.dataType === "company" ? handleModal(true, item) : item.dataType === "event" ? handlePreview(item) : fakeHandle() }}
								>
									{showTitle(item.dataType, item)}
								</button>
							</th>
							<th className="col-2 py-3 my-auto px-4">{getTimeReadableByHumans(item.lastEditTime).dayof}</th>
							<th className="col-2 py-3 my-auto px-4">{getTimeReadableByHumans(item.lastEditTime).timeof}</th>
							<th className="col-2 py-3 my-auto px-4">{item.dataType}</th>
							<th className="col-2 py-3 my-auto px-4">{item.approvalStatus}</th>
							<th className="col-2 py-3 my-auto px-4 d-flex">
								<button onClick={() => handleDeletePrompt(item)} className="btn red-bg text-white"><FaTrashAlt /></button>
								
								{
									item.approvalStatus === 'Pending Approval' && (item.dataType === 'company' || item.dataType === 'event') && (
										<Link className="text-white ml-2" to={{pathname: item.dataType === 'company'? 'AddCompany' : '/AddEvent', state: {redirectInfo: item}}}>
											<button className="btn orange-bg text-white">
												<FaEdit />
											</button>	
										</Link>
									)
								}
								
							</th>
						</tr>
					))
				}
			</tbody>
		</table>
	</div>
  )
}

export default Table
