import React, { useState, useEffect } from 'react'
import getSuggestions, { getPlaceLatLong, getSuggestionsCity } from '../../../functions/getSuggestions';
import debounce from 'lodash/debounce'
import Select from 'react-select'
import styles from '../../AddEvent/languageSelect.module.scss'

const CustomOption = ({ innerProps, isDisabled, data:{label, longLabel} }) =>
  !isDisabled ? (
    <div className="option" {...innerProps}>
      <div className="label">
        {label}
      </div>
      <div className="long-label">
        {longLabel}
      </div>
    </div>
  ) : null;

export const SelectLocation = (props) => {
  const { handleUpdateState, type, id, address } = props

  const [locationOptions, setLocationOptions] = useState([{ value: null, label: "", longLabel:"", url: "" }]);
  const [filters, setFilters] = useState([])

  const handleLocationChange = async (value, {name}) => {
    if (value && value.label){
        handleUpdateState(type, value.label)
    } else {
        handleUpdateState(type, '')
    }
    const currentCoordinates = value && await getPlaceLatLong(value.value)

    setFilters(
        filters.map((filter)=>(
            filter.mainAttribute&&filter.attribute === 'Location' 
            ? {
            ...filter,
            currentCoordinates,
            useFilter:true,
            value:value
            }
            : filter
        ))
    )
  }

  const handleLocationInput = (input, {action}) => {
    if(action === 'input-change'){
      handleUpdateState(type, input)
    }
    if(action === 'input-change' && input.length > 0){
        if(type === 'City') {
            debounce(()=>getSuggestionsCity(input).then((locationOptions)=>setLocationOptions(locationOptions)),500)()
        } else {
            debounce(()=>getSuggestions(input).then((locationOptions)=>setLocationOptions(locationOptions)),500)()
        }
    }
  }

  const locationFilter = filters.find((filter, i)=>
    filter.attribute ==='Location' && filter.mainAttribute
    )
    useEffect(() => {

    }, [address])

  return (
    <Select
		id={id}
        options={locationOptions}
        isClearable={true}
        name="Location"
        value={type === 'City' ? {label: address.city} : type === 'State' ? {label: address.state} : type === 'Country' ? {label: address.country} : {label: ''}}
        onChange={handleLocationChange}
        onInputChange={handleLocationInput}
        components={{
            Option:CustomOption,
            IndicatorSeparator:false,
            DropdownIndicator:false
        }}
        placeholder={type}
        className={styles.root}
        classNamePrefix="react-select"
    />
  )
}
