import React, { useState } from 'react'
import getSuggestions, { getPlaceLatLong, getSuggestionsCity } from '../../../functions/getSuggestions';
import debounce from 'lodash/debounce'
import Select from 'react-select'
import styles from '../languageSelect.module.scss'
import { label } from 'aws-amplify';

const CustomOption = ({ innerProps, isDisabled, data:{label, longLabel} }) =>
  !isDisabled ? (
    <div className="option" {...innerProps}>
      <div className="label">
        {label}
      </div>
      <div className="long-label">
        {longLabel}
      </div>
    </div>
  ) : null;

export const SelectLocation = (props) => {
  const { handleUpdateState, formData, type, id } = props

  const [locationOptions, setLocationOptions] = useState([{ value: null, label: "", longLabel:"", url: "" }]);
  const [filters, setFilters] = useState([])

  const handleLocationChange = async (value, {name}) => {
    const currentCoordinates = value && await getPlaceLatLong(value.value)
    if (value && value.label){
        if(type === 'City') {
            formData.location.address.city = value.label
        } else if(type === 'State') {
            formData.location.address.state = value.label
        } else if(type === 'Country') {
            formData.location.address.country = value.label
        }
        handleUpdateState(formData)
        
    } else {
        if(type === 'City') {
            formData.location.address.city = ''
        } else if(type === 'State') {
            formData.location.address.state = ''
        } else if(type === 'Country') {
            formData.location.address.country = ''
        }
        handleUpdateState(formData)
    }
    setFilters(
        filters.map((filter)=>(
            filter.mainAttribute&&filter.attribute === 'Location' 
            ? {
            ...filter,
            currentCoordinates,
            useFilter:true,
            value:value
            }
            : filter
        ))
    )
  }

  const handleLocationInput = (input, {action}) => {
    if(action === 'input-change'){
      if(type === 'City') {
        formData.location.address.city = input
      } else if(type === 'State') {
          formData.location.address.state = input
      } else if(type === 'Country') {
          formData.location.address.country = input
      }
      handleUpdateState(formData)
    }
    if(action === 'input-change' && input.length > 0){
        if(type === 'City') {
            debounce(()=>getSuggestionsCity(input).then((locationOptions)=>setLocationOptions(locationOptions)),500)()
        } else {
            debounce(()=>getSuggestions(input).then((locationOptions)=>setLocationOptions(locationOptions)),500)()
        }
    }
  }

  const locationFilter = filters.find((filter, i)=>
    filter.attribute ==='Location' && filter.mainAttribute
    )
// {value: "ChIJCXjgokgGl0cRf-63THNV_LY", label: "Karlsrūhė", longLabel: "Vokietija", url: "Karlsrūhė,+Vokietija"}
  return (
    <Select
		    id={id}
        options={locationOptions}
        isClearable={true}
        name="Location"
        value={
          type === 'City' && formData.location.address.city? {label: formData.location.address.city} :
          type === 'State' && formData.location.address.state? {label: formData.location.address.state} :
          type === 'Country' && formData.location.address.country? {label: formData.location.address.country} : ''
        }

        onChange={handleLocationChange}
        onInputChange={handleLocationInput}
        components={{
            Option:CustomOption,
            IndicatorSeparator:false,
            DropdownIndicator:false
        }}
        placeholder={type}
        className={styles.root}
        classNamePrefix="react-select"
    />
  )
}
