import React, { Component } from "react";
import AddEvent from "./AddEvent";
import previewArray from "../../functions/previewArray";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { Redirect } from "react-router-dom";
import checkRequiredFields from "./functions/checkRequiredFields";
import GeneratedApi from "../../functions/generatedApi";

export class AddEventContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        dataId: "-1",
        dataType: "event",
        featured: false,
        majorEvent: "",
        eventType: "",
        eventSegment: [],
        onlineOffline: "",
        onDemand: "",
        populationSize: "",
        title: "",
        date: {
          startTime: "",
          endTime: "",
          startRegister: "",
          endRegister: "",
          winnerAnnounce: "",
        },
        descriptions: {
          longDescription: "",
          shortDescription: "",
        },
        images: {
          squareIcon: "",
          banner: "",
        },
        tags: {
          verticals: [],
          horizontals: [],
        },
        website: "",
        location: {
          address: {
            street1: "",
            street2: "",
            city: "",
            state: "",
            stateCode: "",
            country: "",
            countryCode: "",
            zipCode: "",
            region: "USA",
          },
          venueName: "",
        },
        socialLinks: {
          twitter: "",
          linkedin: "",
          blog: "",
          facebook: "",
          youtube: "",
        },
        registration: "",
        cost: {
          amount: 0,
          currency: "USD",
        },
        prize: {
          prizeDescription: "",
          prizes: "",
        },
        discount: {
          discountCode: "",
          discountDescription: "",
        },
        organizers: [],
        speakers: [],
        sponsors: {
          sponsorLevel: "Gold",
          pgdb_Id: "10101",
          dataId: "2348",
          dataType: "company",
        },
        attendeeTypes: "",
        featuredDataOptIn: "",
        advertisementOptIn: "",
      },
      formCompany: {
        company_address: {
          street_1: null,
          street_2: null,
          city: null,
          state: null,
          zipcode: null,
          country: null,
        },
        company_name: "",
        company_nickname: "",
        company_sponsor_type: "",
        company_url: "",
        dataType: "company",
        dataId: "-1",
      },
      redirect: false,
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { formData } = this.state;
    if (formData.cost.amount === "") {
      formData.cost.amount = 0;
    }
    const q = { data: formData };
    if (checkRequiredFields(formData)) {
      GeneratedApi("", true, false)
        .post("updateEvent", q)
        .then(() => {
          this.setState({ redirect: true });
        })
        .catch((error) => {
          console.log(error.response);
          NotificationManager.error("Failed to submit form", "Error message");
        });
    }
  };

  handleInputUniversal = (e) => {
    const { formData } = this.state;
    formData[e.target.name] = e.target.value;
    this.setState({ formData });
  };

  handlePreview = (e) => {
    const array = previewArray(this.state.formData);
    window.open(`${process.env.REACT_APP_REDIRECT_URL}/events/preview/?q=${escape(array)}`, "_blank");
  };

  handleUpdateState = (state) => {
    this.setState({ state });
  };
  handleUpdateForm = (state) => {
    this.setState({ formData: state });
  };
  render() {
    console.log(this.state.formData, "formdata");
    const { redirect } = this.state;

    if (redirect) {
      return (
        <Redirect
          to={{
            pathname: "/UserPanel",
            state: {
              notification: {
                type: "Success message",
                message: "You successfully add event",
                notify: true,
              },
            },
          }}
        />
      );
    }
    return (
      <div>
        <AddEvent
          handleUpdateState={this.handleUpdateState}
          formData={this.state.formData}
          handleSubmit={this.handleSubmit}
          handlePreview={this.handlePreview}
          handleInputUniversal={this.handleInputUniversal}
          state={this.props.location.state}
          handleUpdateForm={this.handleUpdateForm}
        />
        <NotificationContainer />
      </div>
    );
  }
}

export default AddEventContainer;
