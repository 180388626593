import React, { Component } from 'react'
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { Collapse } from 'reactstrap'

import style from './AdvancedFilterPc.module.scss'
import SelectionRow from './SelectionRow';

export class AdvancedFilterPc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    }
  }
	handleOpenFilters = () => {
		this.setState({isOpen: true})
	}
	handleClearFilters = () => {

	}
	handleCollapseFilters = () => {
		this.setState({isOpen: !this.state.isOpen})
	}

  render() {
  const { isOpen } = this.state;
  const { filterArray, handleAddFilter, selectFilterChange } = this.props.advancedFilter
    return (
	<div className={`position-relative py-2 my-1 bg-white ${style.advanced_form}`}>
		<div className="container">
			<div className="row">
				<div className="col-12">
					<Collapse isOpen={isOpen} >
						<SelectionRow selectFilterChange={selectFilterChange} filterArray={filterArray} />
					</Collapse>
				</div>
			</div>
			<div className="row">
				<div className="col-11 d-flex">
					<div onClick={()=> {handleAddFilter(); this.handleOpenFilters();}} className="d-flex my-auto pointer">
						<div className={`${style.add_icon} mr-1 my-auto`} />
						<span className={`btn pl-0 font-s-14 hover-blue ${style.add}`}>Add more filters</span>
					</div>
					<div className="d-flex my-auto pointer pl-2">
						<div className={`${style.clear_icon} mr-1 my-auto`} />
						<span className={`btn pl-0 black-color-2 font-s-14 ${style.clear}`} onClick={this.handleClearFilters}>Clear All Filters</span>
					</div>
				</div>
				<div style={{color: "#1b7beb"}} className="d-flex pointer col-1" onClick={this.handleCollapseFilters}>
					<div className="m-auto">
					{
						isOpen ?
						<FaArrowUp /> : <FaArrowDown />
					}
					</div>
				</div>
			</div>
		</div>
	</div>
    )
  }
}

export default AdvancedFilterPc
