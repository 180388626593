import React from 'react'
import { Input } from 'reactstrap';
import style from './style.module.scss'

const TopSearchBlock = (props) => {
  const { submit, value, onChange, title, children } = props;
  return (
    <div className={style.root}>
        <div className="background text-center blue-bg p-4 w-100 d-flex d-lg-none">
          <div className="container">
            <div className="row">
              <div className="font-archivo h4 text-align-center text-white mx-auto">
                { title }
              </div>
            </div>
          </div>
        </div>
        <form className="d-none d-lg-block form" onSubmit={submit}>
          <div className="container-fluid d-inline-flex align-items-center background text-center blue-bg height-121">
            <div className="container">
              <div className="d-flex">
                <div className="col-md-6 bg-white border-r-4 search-box">
                  <div className="row">
                    <div className="col-md-8 d-inline-flex align-items-center">
                      <span className="image-wrapper w-100">
                        <Input className="w-100" type="search" value={value} placeholder="Search the IoT ecosystem" aria-label="Search" onChange={onChange}/>
                      </span>
                    </div>
                    <div className="col-md-4 text-right pr-0">
                      <button className="btn text-white border-r-4 py-0 px-4 blue-bg-2 search" type="submit" onSubmit={submit}>Search</button>
                    </div>
                  </div>
                </div>
                { children }
              </div>
            </div>
          </div>
          
        </form>
    </div>
  )
}

export default TopSearchBlock
