import React from 'react'

const Discount = (props) => {
  const { formData } = props

  const handleDiscount = (e) => {
	formData.discount[e.target.name] = e.target.value
	props.handleUpdateState(formData)
  }

  return (
    <div>
        <div className="row">
            <div className="col-12 col-md-6">
                <h5 className="mb-3">Discount Code</h5>
                <div className="form-group">
                    <input value={formData.discount.discountCode} onChange={handleDiscount} id="discountCode" name="discountCode" type="text" className="form-control" placeholder="Discount Code"/>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-12">
                <h5 className="mb-3">Discount Description</h5>
                <div className="form-group">
                    <input value={formData.discount.discountDescription} onChange={handleDiscount} id="discountDescription" name="discountDescription" type="text" className="form-control" placeholder="Please Enter Discount Description e.g. Take 20% OFF"/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Discount
