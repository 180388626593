import React from 'react'

const EventCostAndAttendees = (props) => {
  const { formData } = props

  const handleCost = (e) => {
	formData.cost.amount = e.target.value
	props.handleUpdateState(formData)
  }

  const handleAtend = (e) => {
	formData[e.target.name] = e.target.value
	props.handleUpdateState(formData)
  }

  return (
    <div className="row">
        <div className="col-12 col-lg-6">
            <h5 className="mb-3">Event Cost<sup className="text-danger">*</sup></h5>
            <div className="form-group mb-0">
                <input value={formData.cost.amount} onChange={handleCost} id="cost amount" name="cost amount" type="text" className="form-control" placeholder="e.g. 25 USD"/>
            </div>
            <div><small className="text-danger">If multiple prices, specify range</small></div>
            <div className="mb-3"><small className="text-danger">If free, put 0</small></div>
        </div>
        <div className="col-12 col-lg-6">
            <h5 className="mb-3">Number of Attendees</h5>
            <div className="form-group">
                <input value={formData.populationSize} onChange={handleAtend} id="populationSize" name="populationSize" type="text" className="form-control" placeholder="e.g. 200"/>
            </div>
        </div>
    </div>
  )
}

export default EventCostAndAttendees
