import React from 'react'
import RequiredFields from '../../../functions/RequiredFields';
import scrollToObject from '../../../functions/scrollToObject';
import specialRequiredFields from '../../../functions/specialRequiredFields';
import specialScrollToObject from '../../../functions/specialScrollToObject';

const checkRequiredFields = (formData) => {

	let eventTitle = document.getElementById("event-title");
	let eventType = document.getElementById("eventType");
	let eventForm = document.getElementById("onlineOffline");
	let startDate = document.getElementById("StartDate")
	let endDate = document.getElementById("EndDate")
	let eventCostAmount = document.getElementById("cost amount")
	let registration = document.getElementById("registration")
	let organizers = document.getElementById("organizers")
	let street1 = document.getElementById("street1")
	let city = document.getElementById("city").children[0]
	let state = document.getElementById("state").children[0]
	let country = document.getElementById("country").children[0]
	let zipCode = document.getElementById("zipCode")
	let longDescription = document.getElementById("longDescription").lastChild
	let featureEvent = document.getElementById("featuredDataOptIn")
	let advertiseEvent = document.getElementById("advertisementOptIn")

	RequiredFields(eventTitle)
	RequiredFields(eventType)
	RequiredFields(eventForm)
	RequiredFields(startDate)
	RequiredFields(endDate)
	RequiredFields(eventCostAmount)
	RequiredFields(registration)
	specialRequiredFields(formData.organizers.length > 0 ? false : true ,organizers, 'text')
	eventForm.value !== 'Online' ?
	  RequiredFields(street1) :
	  specialRequiredFields(false, street1, 'border')
	eventForm.value !== 'Online' ?
	  specialRequiredFields(formData.location.address.city.trim() === '' ? true : false, city, 'border') :
	  specialRequiredFields(false, city, 'border')
	eventForm.value !== 'Online' ?
	  specialRequiredFields(formData.location.address.state.trim() === '' ? true : false, state, 'border') :
	  specialRequiredFields(false, state, 'border')
	eventForm.value !== 'Online' ?
	  specialRequiredFields(formData.location.address.country.trim() === '' ? true : false, country, 'border') :
	  specialRequiredFields(false, country, 'border')
	eventForm.value !== 'Online' ?
	  RequiredFields(zipCode) :
	  specialRequiredFields(false, zipCode, 'border')
	specialRequiredFields(!formData.descriptions.longDescription, longDescription, 'border')
	specialRequiredFields(formData.featuredDataOptIn === "" ? true : false, featureEvent, 'border')
	specialRequiredFields(formData.advertisementOptIn === "" ? true : false, advertiseEvent, 'border')

    if (
		!scrollToObject(eventTitle) ||
		!scrollToObject(eventType) ||
		!scrollToObject(eventForm) ||
		!scrollToObject(startDate) ||
		!scrollToObject(endDate) ||
		!scrollToObject(eventCostAmount) ||
		!scrollToObject(registration) ||
		!specialScrollToObject(formData.organizers.length > 0 ? false : true, organizers) ||
		( eventType.value !== 'Virtual Conference' && !scrollToObject(street1) ) ||
		( eventType.value !== 'Virtual Conference' && !specialScrollToObject(formData.location.address.city.trim() === '' ? true : false, city) ) ||
		( eventType.value !== 'Virtual Conference' && !specialScrollToObject(!formData.location.address.state.trim() === '' ? true : false, state) ) ||
		( eventType.value !== 'Virtual Conference' && !specialScrollToObject(!formData.location.address.country.trim() === '' ? true : false, country) ) ||
		( eventType.value !== 'Virtual Conference' && !scrollToObject(zipCode) ) ||
		!specialScrollToObject(!formData.descriptions.longDescription, longDescription) ||
		!specialScrollToObject(formData.featuredDataOptIn === "" ? true : false, featureEvent) ||
		!specialScrollToObject(formData.advertisementOptIn === "" ? true : false, advertiseEvent)

    ) {
        return false;
	}

	return true;
}

export default checkRequiredFields
