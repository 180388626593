import React, { useEffect } from 'react'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import { SelectLocation } from './components/SelectLocation'

const AddCompany = (props) => {

  const { handleSubmit, state, handleUpdateState, address } = props

  useEffect(() => {
	if(state && state.redirectInfo) {
		document.getElementById("comapnyOfficalName").value = state.redirectInfo.company_name
    	document.getElementById("companyNickname").value = state.redirectInfo.company_nickname
		document.getElementById("companyURL").value = `www${(state.redirectInfo.company_url).split('www')[1]}`
		document.getElementById("companUrl type").value = (state.redirectInfo.company_url).includes('http') ? 'http://' : 'https://'
    	document.getElementById("address1").value = state.redirectInfo.company_address.street_1
        document.getElementById("address2").value = state.redirectInfo.company_address.street_2
        handleUpdateState('City', state.redirectInfo.company_address.city)
        handleUpdateState('State', state.redirectInfo.company_address.state)
        handleUpdateState('Country', state.redirectInfo.company_address.country)
    	document.getElementById("zipcode").value = state.redirectInfo.company_address.zipcode
	}
  }, [])
  return (
    <div>
        <Breadcrumbs last="Add Company" homePath="/UserPanel" />
        <div className="container">
            <div className="row">
                <div className="col-12 offset-md-2 col-md-8">
                    <h4 className="text-center mb-4">Company Information</h4>
                    <br />

                    <div className="row">
                        <div id="object" className="col-12 col-md-6">
                            <div className="form-group">
                                <h5 className="mb-3">Company Offical Name<sup className="text-danger">*</sup></h5>
                                <input type="text" className="form-control" id="comapnyOfficalName" placeholder="" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <h5 className="mb-3">Nickname (Optional)</h5>
                                <input type="text" required className="form-control" id="companyNickname" placeholder="" />
                            </div>
                        </div>
                    </div>

                    <hr />

                    <form>
                        <div id="block-companyURL" className="form-group">
                            <h5 className="mb-3">Company Website(URL)<sup className="text-danger">*</sup></h5>
                            <div className="row">
                                <div className="col-4 col-xl-3 pr-0">
                                    <select id="companUrl type" name="Company Website" className="form-control pr-0">
                                        <option value="https://">https</option>
                                        <option value="http://">http</option>
                                    </select>
                                </div>
                                <div className="col pl-0">
                                    <input type="text" className="form-control" id="companyURL" placeholder="www.google.com/" />
									<small className="text-danger pl-3"></small>
                                </div>
                            </div>
                        </div>

                        <hr />

                        <div className="form-group">
                        	<h5 className="mb-3">Address Line 1<sup className="text-danger">*</sup></h5>
                            <input type="text" className="form-control" id="address1" placeholder="" />
                        </div>

                        <div className="form-group">
                            <h5 className="mb-3">Address Line 2 (Optional)</h5>
                            <input type="text" className="form-control" id="address2" placeholder="" />
                        </div>

                        <div className="form-group">
                            <h5 className="mb-3">City<sup className="text-danger">*</sup></h5>
                            <SelectLocation address={address} handleUpdateState={handleUpdateState} type="City" id="city" />
                        </div>
                        <div className="form-group">
                            <h5 className="mb-3">State<sup className="text-danger">*</sup></h5>
                            <SelectLocation address={address} handleUpdateState={handleUpdateState} type="State" id="state" />
                        </div>
                        <div className="form-group">
                            <h5 className="mb-3">Country<sup className="text-danger">*</sup></h5>
                            <SelectLocation address={address} handleUpdateState={handleUpdateState} type="Country" id="country" />
                        </div>

                        <div className="form-group">
                            <h5 className="mb-3">Zip Code<sup className="text-danger">*</sup></h5>
                            <input type="text" className="form-control" id="zipcode" placeholder="" />
                        </div>
                        {/* <div className="form-group mt-5">
                            <h5 className="mb-3">Company Description (250 words or less)</h5>
                            <Editor
                                initialValue="<p>200 words or less</p>"
                                className="form-control"
                                init={{
                                    height: 300,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image charmap print preview anchor textcolor',
                                        'searchreplace visualblocks code fullscreen',
                                        'insertdatetime media table paste code help wordcount'
                                    ],
                                    toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                                }}
                                onEditorChange={handleDescriptions}
                                textareaName="shortDescription"
                                value={description}
                            />
                        </div> */}

                        {/* <div className="form-group mt-3">
                            <h5 className="mb-3">Company Twitter Link</h5>
                            <input type="text" className="form-control" id="twitter link" placeholder="" />
                        </div>

                        <div className="form-group">
                            <h5 className="mb-3">Company Linkedin Link</h5>
                            <input type="text" className="form-control" id="linkedin link" placeholder="" />
                        </div>

                        <div className="form-group">
                            <h5 className="mb-3">Company Facebook Link</h5>
                            <input type="text" className="form-control" id="facebook link" placeholder="" />
                        </div> */}

                        {/* <div className="form-group mt-3">
                            <h5 className="mb-3">Company Blog Link</h5>
                            <input type="text" className="form-control" id="blog link" placeholder="" />
                        </div>

                        <div className="form-group mt-3">
                            <h5 className="mb-3">Company News Link</h5>
                            <input type="text" className="form-control" id="news link" placeholder="" />
                        </div>

                        <div className="form-group mt-3">
                            <h5 className="mb-3">Company RSS Feed</h5>
                            <input type="text" className="form-control" id="rss feed" placeholder="" />
                        </div>
                        
                        <h4 className="text-center my-4">Your Information</h4>

                        <div className="form-group mt-3">
                            <h5 className="mb-3">Email Address of Person Adding Company</h5>
                            <input type="text" className="form-control" id="adding company email" placeholder="" />
                        </div>

                        <div className="form-group mt-3">
                            <h5 className="mb-3">Linkedin Link of Person Adding Company</h5>
                            <input type="text" className="form-control" id="adding company linkedin" placeholder="" />
                        </div> */}
                        
                        <div className="row">
                            <button type="submit" className="btn orange-bg text-white mx-auto my-4" onClick={handleSubmit}>Submit</button>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AddCompany
