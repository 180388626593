import React from "react";
import moment from "moment-timezone";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import { Route, BrowserRouter, Switch, Redirect } from "react-router-dom";
import Cookies from "universal-cookie";
import Header from "./components/Header/Header";
import AdminPanelController from "./Pages/AdminPanel/AdminPanelController";
import UserPanelController from "./Pages/UserPanel/UserPanelController";
import Login from "./Pages/Login/Login";
import AddEventContainer from "./Pages/AddEvent/AddEventContainer";
import AddCompanyContainer from "./Pages/AddCompany/AddCompanyController";
import AddCompanyCopy from "./Pages/AddCompanyCopy/AddCompanyController";
import * as jwtDecode from "jwt-decode";

import "react-notifications/lib/notifications.css";
import "bootstrap/dist/css/bootstrap.css";
import "./App.scss";
import Logincheck from "./functions/loginCheck";

const cookies = new Cookies();

class App extends React.Component {
  constructor(props) {
    super(props);

    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_KEY}&v=3.exp&libraries=geometry,drawing,places`;
    script.async = true;
    document.body.appendChild(script);

    this.state = {
      isAuthenticated: false,
      token: false,
      user: false,
      loginLoading: false,
    };
    // check is login token exist, not expired and meybe login came from UGC
    const saveState = (getData) => {
      this.state = { ...this.state, ...getData };
    };
    Logincheck("", saveState);
  }
  setLoading = (status) => {
    this.setState({ loginLoading: status });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      id_token: this.state.token,
      userName: this.state.user,
    };
    const { loginLoading, user } = this.state;
    console.log(this.state, "app js state ---");
    console.log("----NEW VERSION IS UP 2.0.0-----");
    //   const g = "eyJraWQiOiJTUlA5WUJjZEJXQTV4dUFuOHlFSUIwNGJ4aWZMSTBqeFVxaHFrUmdHcENjPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI3YzRmZmIzNy1jYWNlLTQwODUtOGNjNS0xNWE0ZmMxN2Q2YWUiLCJjb2duaXRvOmdyb3VwcyI6WyJwcm8iXSwiZW1haWxfdmVyaWZpZWQiOnRydWUsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy1lYXN0LTEuYW1hem9uYXdzLmNvbVwvdXMtZWFzdC0xX1VBSDA0WHlNVyIsInBob25lX251bWJlcl92ZXJpZmllZCI6dHJ1ZSwiY29nbml0bzp1c2VybmFtZSI6IjdjNGZmYjM3LWNhY2UtNDA4NS04Y2M1LTE1YTRmYzE3ZDZhZSIsImN1c3RvbTphcGlfa2V5X211dGFibGUiOiJLeXh5SHV5a01JN3B2QkhvSlVMUnM1OGdTMGVFZEx6bTlRekVPS2RWIiwiYXVkIjoiMWZ1c241bzlpMGY1OGprYmg2a3Uzam1mNmsiLCJldmVudF9pZCI6ImI4NmMxZWM3LTIxNDktNDc2Ny05N2ViLWEwOTg1MWMwNjhlNSIsInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNTk5NTA1NDMzLCJwaG9uZV9udW1iZXIiOiIrMTY0NjUwNDY0ODIiLCJleHAiOjE1OTk1MDkwMzMsImlhdCI6MTU5OTUwNTQzMywiZW1haWwiOiJtaXRjaGVsbF9wcm8xMUBpb3RuYXRpb24uY29tIn0.ZlQWMF7Bv6KPGRr2OPLxQaUG5BQrQW0iqIFqwK1-desvLCSHffIsHomZKWOEnX00A7h6m-A0nonuD6qSASaAcNrY-nT0eBRgNVDbh45YYTUYx1n4kQ2B4q0N-gmqKJFsZg2Jp64Oqkr3PY3R-fqTLT25W6fROGfZE729QEcYxFEEr4F0er-wnMznOKmoDycufx-_1qAzoDgYhmAbCTahwrZlV7dk6BfTqHB12GFV3YU14ZVSseUoWdFwfEWj4NqOxybEOtlH5Yx2mHLXshtmbd6wexHuyWfRy6t34vH_q67aekTPUyVaQOOurBQ9-QJAiduZQ9RbY5Jzkv1S3c1wSg"
    // const r =
    //   "eyJraWQiOiJTUlA5WUJjZEJXQTV4dUFuOHlFSUIwNGJ4aWZMSTBqeFVxaHFrUmdHcENjPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIwYTNiMWQxZi00OTc2LTQwYTMtOWIxYi1iNWEzMWMxOTk1ZWYiLCJjb2duaXRvOmdyb3VwcyI6WyJhZG1pbiJdLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy1lYXN0LTEuYW1hem9uYXdzLmNvbVwvdXMtZWFzdC0xX1VBSDA0WHlNVyIsInBob25lX251bWJlcl92ZXJpZmllZCI6dHJ1ZSwiY29nbml0bzp1c2VybmFtZSI6IjBhM2IxZDFmLTQ5NzYtNDBhMy05YjFiLWI1YTMxYzE5OTVlZiIsImN1c3RvbTphcGlfa2V5X211dGFibGUiOiI0R2t3aG5QM1M2MmhXV1hTc0dRVnIxSjBHa2lMSU10bjlzd0JqRzlFIiwiYXVkIjoiMWZ1c241bzlpMGY1OGprYmg2a3Uzam1mNmsiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTYxMjUzMTA4MywibmFtZSI6IktyaXN0aWpvbmFzIFNlbmRyYXVza2FzIiwicGhvbmVfbnVtYmVyIjoiKzM3MDYyMDc0NDkyIiwiZXhwIjoxNjEyNTM0NjgzLCJpYXQiOjE2MTI1MzEwODMsImVtYWlsIjoia3Jpc3Rpam9uYXNAaXZlamFzLmx0In0.NglMdlpGmj2Ht56uB0QF-GTOMg7AHbhqv0LSdjsf1FNVHhnLoLjS-fAD37xWjyrXsQi0oD8bvAtZ3OleQDKWVc4rJ-rx3nB5JSP-imLS6mAr4S4p35BdVk4DFXzguip9Z8_l2ZzBS7V58PmAFslqWXzRwPAvlYV5avK5FV1RREancDzR3npKvUniKaNCERYIORqbKOurYKu-sgWk-mkDeoaIqmjwLB9451thnLg1Bq2PzR2cH8CDmGu1ogx_j7syA05NI-0ujiuN9-1GvoxEUq6_oYrFSaqXTG-AbROrLoMQbNd7oW8gtKYwadrCiqwLoMCSaDp0ZDLTNQcjAVM-1w";
    // console.log("paziurim", jwtDecode(r));

    if (user) {
      if (user["cognito:groups"] && user["cognito:groups"].includes("admin")) {
        return (
          <BrowserRouter>
            <Route path="/" render={(props) => <Header {...childProps} {...props} />} />
            <Switch>
              <Route path="/AdminPanel" render={(props) => <AdminPanelController {...childProps} {...props} />} />
              <Route path="/">
                <Redirect to="/AdminPanel" />
              </Route>
            </Switch>
          </BrowserRouter>
        );
      } else {
        return (
          <BrowserRouter>
            <Route path="/" render={(props) => <Header {...props} {...childProps} />} />
            <Switch>
              <Route path="/UserPanel" render={(props) => <UserPanelController {...childProps} {...props} />} />
              <Route path="/AddEvent" render={(props) => <AddEventContainer {...childProps} {...props} />} />
              <Route path="/AddCompany" render={(props) => <AddCompanyContainer {...childProps} {...props} />} />
              <Route path="/AddCompanyCopy" render={(props) => <AddCompanyCopy {...childProps} {...props} />} />
              <Route path="/">
                <Redirect to="/UserPanel" />
              </Route>
            </Switch>
          </BrowserRouter>
        );
      }
    } else {
      return (
        <BrowserRouter>
          <Switch>
            <Route
              path="/login"
              render={(props) => <Login user={user} setLoading={this.setLoading} loginLoading={loginLoading} {...props} {...childProps} />}
            />
            <Route path="/">
              <Redirect to="/login" />
            </Route>
          </Switch>
        </BrowserRouter>
      );
    }
  }
}

export default App;
