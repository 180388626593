import React from 'react'
import AdvancedFilterPc from '../../components/AdvancedFilterPc/AdvancedFilterPc'
import Table from './component/Table'

const AdminPanel = (props) => {

  const { advancedFilter, tableProps } = props
  return (
	<div>
		
		<AdvancedFilterPc advancedFilter={advancedFilter} />
		<Table tableProps={tableProps} />
		
	</div>
  )
}

export default AdminPanel
