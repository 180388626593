import React, { useState } from 'react'
import { CustomModal } from '../../../components/Modal/CustomModal'
import previewArray from '../../../functions/previewArray'
import RowTable from './RowTable'

const Table = (props) => {
  const { responseArray } = props.tableProps
  const [ modal, setModal ] = useState(false)
  const [ dataItem, setItem ] = useState('')

  const showTitle = (dataType, item) => {
	switch (dataType) {
		case "event":
			return ( item.title )
		case "contact":
			return ( `${item.firstName} ${item.lastName}` )
		case "company":
			return ( item.company_name )
		default:
			console.log("Something has gone horribly wrong")
	}
  }

  const getTimeReadableByHumans = datestring => {
    let a = new Date(datestring * 1000);
    let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let year = a.getFullYear();
    let month = months[a.getMonth()];
    let date = a.getDate();
    let hour = a.getHours();
    let min = a.getMinutes();
    if (min < 10) min = "0" + min;

    let exactDate = date + ' ' + month + ' ' + year;
    let exactTime = hour + ':' + min;

    let time = {
        dayof: exactDate,
        timeof: exactTime
    }
    return time;
  }

  const getSymbol = bool => {
    if (bool === true) return "✔";
    else return null;
 }
  const handleModal = (status, item) => {
	  setItem(item)
	  setModal(status)
  }
  const modalBody = (info) => {
    return(
      <div>
          <div className="d-flex pt-1 pb-3 px-1 border-bottom">
              <div> Company Name </div>
              <div className="ml-auto mr-0"> {info.company_name} </div>
          </div>
          <div className="d-flex py-3 px-1 border-bottom">
              <div> Company Nickname </div>
              <div className="ml-auto mr-0"> {info.company_nickname} </div>
          </div>
          <div className="d-flex py-3 px-1 border-bottom">
              <div> Company Url </div>
              <div className="ml-auto mr-0"> {info.company_url} </div>
          </div>
          <div className="d-flex py-3 px-1 border-bottom">
              <div> City </div>
              <div className="ml-auto mr-0"> {info.company_address && info.company_address.city} </div>
          </div>
          <div className="d-flex py-3 px-1 border-bottom">
              <div> Country </div>
              <div className="ml-auto mr-0"> {info.company_address && info.company_address.country} </div>
          </div>
          <div className="d-flex py-3 px-1 border-bottom">
              <div> State </div>
              <div className="ml-auto mr-0"> {info.company_address && info.company_address.state} </div>
          </div>
          <div className="d-flex py-3 px-1 border-bottom">
              <div> Street 1 </div>
              <div className="ml-auto mr-0"> {info.company_address && info.company_address.street_1} </div>
          </div>
          <div className="d-flex py-3 px-1 border-bottom">
              <div> Street 2 </div>
              <div className="ml-auto mr-0"> {info.company_address && info.company_address.street_2} </div>
          </div>
          <div className="d-flex pt-3 pb-1 px-1">
              <div> Zip Code </div>
              <div className="ml-auto mr-0"> {info.company_address && info.company_address.zipcode} </div>
          </div>
      </div>
    )
  }
  const modalHeader = (info) => {
    return (
      <div className="h3 text-center">
          {info.dataType === 'company' ? 'Company info' : 'Event info'}
      </div>
    )
 }
 const handlePreview = (info) => {
	const array = previewArray(info)
	window.open(`${process.env.REACT_APP_REDIRECT_URL}/events/preview/?q=${escape(array)}`, '_blank');
  }

  return (
    <div className="card-body">
        <table id="" className="table text-center">
            <thead>
                <tr>
                {/* {this.state.sortType === 'desc' ? <FaChevronDown /> : <FaChevronUp />} */}
                    <td className="font-weight-bold">Submission Title</td>
                    <td  className="pointer font-weight-bold">Date </td>
                    <td className="font-weight-bold">Type</td>
                    <td className="font-weight-bold">Status</td>
                    <td className="font-weight-bold">User</td>
                    <td className="font-weight-bold">Ad Req.</td>
                        <td className="font-weight-bold">Feature Req.</td> 
                    <td className="font-weight-bold">Major</td>
                    <td className="font-weight-bold">Feature</td>
                    <td className="font-weight-bold">Action</td>
                </tr>
            </thead>
            <tbody>
            <CustomModal
              handleModal={handleModal}
              modal={modal}
              modalBody={modalBody(dataItem)}
              modalHeader={modalHeader(dataItem)}
            />
            {
                responseArray.map((item, key) => (
                    <RowTable
						            key={key}
                        showTitle={showTitle}
                        getTimeReadableByHumans={getTimeReadableByHumans}
                        item={item}
                        id={key}
                        getSymbol={getSymbol}
                        handleModal={handleModal}
                        handlePreview={handlePreview}
                        {...props.tableProps}
                    />
                ))
            }
            </tbody>
        </table>
    </div>
  )
}

export default Table
