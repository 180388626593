import React, { useEffect } from 'react'
import CustomCheckBox from '../../../components/CustomCheckBox/CustomCheckBox'
import EventVerticals from '../../../jsonFiles/EventVerticals.json'
import EventsHorizontals from '../../../jsonFiles/EventsHorizontals.json'

const Tags = (props) => {
  const { redirectInfo } = props
  
  const handleTags = () => {
    const { formData } = props
    formData.tags.verticals = EventVerticals.filter((item) => {
        return document.getElementById(item).checked
    })
    formData.tags.horizontals = EventsHorizontals.filter((item) => {
        return document.getElementById(item).checked
    })
    if( document.getElementById('extraVertical').value !== '' ) {
        formData.tags.verticals = [...formData.tags.verticals, document.getElementById('extraVertical').value ]
    }
    if( document.getElementById('extraHorizontal').value !== '' ) {
        formData.tags.horizontals = [...formData.tags.horizontals, document.getElementById('extraHorizontal').value ]
    }
    props.handleUpdateState(formData)
  }

  useEffect(() => {
    if(redirectInfo) {
        const verticals = EventVerticals.filter((item) => {
            redirectInfo.tags.verticals.includes(item) && (document.getElementById(item).checked = true)
        })
        const horizontals = EventsHorizontals.filter((item) => {
            redirectInfo.tags.horizontals.includes(item) && (document.getElementById(item).checked = true)
        })
        const exVerticals = redirectInfo.tags.verticals.filter((item) => {
            !EventVerticals.includes(item) && (document.getElementById("extraVertical").value = item)
        })
        const exHorizontals = redirectInfo.tags.horizontals.filter((item) => {
            !EventsHorizontals.includes(item) && (document.getElementById("extraHorizontal").value = item)
        })
    }
  }, [])
  return (
    <div className="row">
        <div className="col-12 col-md-6">
            <h5 id="vertical-tags" className="my-3">Vertical Tags</h5>
            {
            EventVerticals.map((item, key) => (
                <label key={`verticals-${key}`} className="d-flex my-2" htmlFor={item}>
                    <CustomCheckBox
                        name='vertical'
                        id={item}
                        onChange={handleTags}
                    />
                    <span className="ml-2">{item}</span>
                </label>
            ))
            }
            <input onChange={handleTags} id="extraVertical" type="text" className="form-control mt-3" placeholder="Other Vertical Tags"/>
        </div>
        <div className="col-12 col-md-6">
        <h5 id="horizontal-tags" className="my-3">Horizontal Tags</h5>
            {
            EventsHorizontals.map((item, key) => (
                <label key={`horizontals-${key}`} className="d-flex my-2" htmlFor={item}>
                    <CustomCheckBox
                        name='horizontals'
                        id={item}
                        onChange={handleTags}
                    />
                    <span className="ml-2">{item}</span>
                </label>
            ))
            }
            <input onChange={handleTags} id="extraHorizontal" type="text" className="form-control mt-3" placeholder="Other Horizontal Tags"/>
        </div>
    </div>
  )
}

export default Tags
