import React from 'react'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import { Editor } from '@tinymce/tinymce-react';

const AddCompany = (props) => {

  const { handleSubmit, description, handleDescriptions } = props
  return (
    <div>
        <Breadcrumbs last="Add Company" homePath="/UserPanel" />
        <div className="container">
            <div className="row">
                <div className="col-12 offset-md-2 col-md-8">
                    <h4 className="text-center mb-4">Company Information</h4>
                    <br />

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <h5 className="mb-3">Company Offical Name<sup className="text-danger">*</sup></h5>
                                <input type="text" className="form-control" id="comapnyOfficalName" placeholder="" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <h5 className="mb-3">Nickname (Optional)</h5>
                                <input type="text" required className="form-control" id="companyNickname" placeholder="" />
                            </div>
                        </div>
                    </div>

                    <hr />

                    <form>
                        <div className="form-group">
                            <h5 className="mb-3">Company Website(URL)<sup className="text-danger">*</sup></h5>
                            <input type="text" className="form-control" id="companyURL" placeholder="https://www.google.com/" />
                            <small id="httpsHelp" className="form-text text-muted">Please include https</small>
                        </div>

                        <hr />

                        <div className="form-group">
                        <h5 className="mb-3">Address Line 1<sup className="text-danger">*</sup></h5>
                            <input type="text" className="form-control" id="address1" placeholder="" />
                        </div>

                        <div className="form-group">
                            <h5 className="mb-3">Address Line 2 (Optional)</h5>
                            <input type="text" className="form-control" id="address2" placeholder="" />
                        </div>

                        <div className="form-group">
                            <h5 className="mb-3">City<sup className="text-danger">*</sup></h5>
                            <input type="text" className="form-control" id="city" placeholder="" />
                        </div>

                        <div className="form-group">
                            <h5 className="mb-3">State<sup className="text-danger">*</sup></h5>
                            <input type="text" className="form-control" id="state" placeholder="" />
                        </div>

                        <div className="form-group">
                            <h5 className="mb-3">Country<sup className="text-danger">*</sup></h5>
                            <input type="text" className="form-control" id="country" placeholder="" />
                        </div>

                        <div className="form-group">
                            <h5 className="mb-3">Zip Code<sup className="text-danger">*</sup></h5>
                            <input type="text" className="form-control" id="zipcode" placeholder="" />
                        </div>

                        <div className="form-group mt-5">
                            <h5 className="mb-3">Company Description (250 words or less)</h5>
                            <Editor
                                initialValue="<p>200 words or less</p>"
                                className="form-control"
                                init={{
                                    height: 300,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image charmap print preview anchor textcolor',
                                        'searchreplace visualblocks code fullscreen',
                                        'insertdatetime media table paste code help wordcount'
                                    ],
                                    toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                                }}
                                onEditorChange={handleDescriptions}
                                textareaName="shortDescription"
                                value={description}
                            />
                        </div>

                        <div className="form-group mt-3">
                            <h5 className="mb-3">Company Twitter Link</h5>
                            <input type="text" className="form-control" id="twitter link" placeholder="" />
                        </div>

                        <div className="form-group">
                            <h5 className="mb-3">Company Linkedin Link</h5>
                            <input type="text" className="form-control" id="linkedin link" placeholder="" />
                        </div>

                        <div className="form-group">
                            <h5 className="mb-3">Company Facebook Link</h5>
                            <input type="text" className="form-control" id="facebook link" placeholder="" />
                        </div>

                        <div className="form-group mt-3">
                            <h5 className="mb-3">Company Blog Link</h5>
                            <input type="text" className="form-control" id="blog link" placeholder="" />
                        </div>

                        <div className="form-group mt-3">
                            <h5 className="mb-3">Company News Link</h5>
                            <input type="text" className="form-control" id="news link" placeholder="" />
                        </div>

                        <div className="form-group mt-3">
                            <h5 className="mb-3">Company RSS Feed</h5>
                            <input type="text" className="form-control" id="rss feed" placeholder="" />
                        </div>
                        
                        <h4 className="text-center my-4">Your Information</h4>

                        <div className="form-group mt-3">
                            <h5 className="mb-3">Email Address of Person Adding Company</h5>
                            <input type="text" className="form-control" id="adding company email" placeholder="" />
                        </div>

                        <div className="form-group mt-3">
                            <h5 className="mb-3">Linkedin Link of Person Adding Company</h5>
                            <input type="text" className="form-control" id="adding company linkedin" placeholder="" />
                        </div>
                        
                        <div className="row">
                            <button type="submit" className="btn orange-bg text-white mx-auto my-4" onClick={handleSubmit}>Submit</button>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AddCompany
