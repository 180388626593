import React, { Component } from 'react'
import TopSearchBlock from '../../components/TopSearchBlock/TopSearchBlock'
import AdvancedFilterPc from '../../components/AdvancedFilterPc/AdvancedFilterPc';
import Table from './components/Table';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'

export default class UserPanel extends Component {
  constructor(props) {
	super(props);
	this.state = {
		
	}
  }

  render() {
  const { submitSearch, searchVal, handleSearchChange, advancedFilter, tableProps } = this.props;

	return (
	<div>
		<Breadcrumbs homePath="/UserPanel" />
		<TopSearchBlock submit={submitSearch} value={searchVal} onChange={handleSearchChange} title='UserPanel' />
		<AdvancedFilterPc advancedFilter={advancedFilter} />
		<Table tableProps={tableProps} />
	</div>
	)
  }
}
