import React, { useEffect } from "react";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { Location } from "./components/Location";
import Descriptions from "./components/Descriptions";
import Tags from "./components/Tags";
import Advertising from "./components/Advertising";
import TypeAndFormat from "./components/TypeAndFormat";
import Segment from "./components/Segment";
import StartTimeAndEndTime from "./components/StartTimeAndEndTime";
import EventCostAndAttendees from "./components/EventCostAndAttendees";
import Discount from "./components/Discount";
import BannersAndRegistration from "./components/BannersAndRegistration";
import Media from "./components/Media";
import Atendee from "./components/Atendee";
import AddOrganizers from "./components/AddOrganizers";

const AddEvent = (props) => {
  const { handleSubmit, handlePreview, formData, handleUpdateState, handleInputUniversal, state, handleUpdateForm } = props;

  useEffect(() => {
    if (state && state.redirectInfo) {
      handleUpdateForm(state.redirectInfo);
    }
  }, []);

  return (
    <div>
      <Breadcrumbs last="Add Event" homePath="/UserPanel" />
      <div className="container">
        <div className="row">
          <div className="col-12 offset-md-2 col-md-8">
            <h5 className="mt-3">
              Title<sup className="text-danger">*</sup>
            </h5>
            <input
              value={formData.title}
              onChange={handleInputUniversal}
              id="event-title"
              name="title"
              type="text"
              className="form-control"
              placeholder="Title"
            />
            <TypeAndFormat handleUpdateState={handleUpdateState} formData={formData} />
            <Segment handleUpdateState={handleUpdateState} formData={formData} />
            <StartTimeAndEndTime handleUpdateState={handleUpdateState} formData={formData} />
            <EventCostAndAttendees handleUpdateState={handleUpdateState} formData={formData} />
            <Discount handleUpdateState={handleUpdateState} formData={formData} />
            <BannersAndRegistration redirectInfo={state && state.redirectInfo} handleUpdateState={handleUpdateState} formData={formData} />
            <div className="row">
              <Media redirectInfo={state && state.redirectInfo} handleUpdateState={handleUpdateState} formData={formData} />
              <Atendee redirectInfo={state && state.redirectInfo} handleUpdateState={handleUpdateState} formData={formData} />
            </div>
            <AddOrganizers redirectInfo={state && state.redirectInfo} handleUpdateState={handleUpdateState} formData={formData} />
            <Location handleUpdateState={handleUpdateState} formData={formData} />
            <Descriptions handleUpdateState={handleUpdateState} formData={formData} />
            <Tags redirectInfo={state && state.redirectInfo} handleUpdateState={handleUpdateState} formData={formData} />
            <Advertising handleUpdateState={handleUpdateState} formData={formData} />
          </div>
        </div>
        <div className="row">
          <div className="row mx-auto">
            <div className="mr-2 my-4">
              <button onClick={handleSubmit} className="btn orange-bg text-white">
                Submit
              </button>
            </div>
            <div className="ml-2 my-4">
              <button onClick={handlePreview} type="button" className="btn orange-bg text-white">
                Preview
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEvent;
